import { TUserStore } from '@/stores/User/Domain';
import { TRouterGuard } from '@/@types/router';
import { useUser } from '@/composables/useUser';

export default function ({ to, from, next }: TRouterGuard, store: TUserStore) {
  if (!store.isAuth) {
    return;
  }

  if (store.isDoctor || store.isPatient) {
    next();
    return;
  }

  const { userProfileLink } = useUser();
  next({ name: userProfileLink.value });
}
