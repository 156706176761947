import type { Admin, Doctor, Patient, User } from '@/Api/Api';

export enum EEnv {
  DEV = 'development',
  PROD = 'production'
}

export enum EVerificationIdStatus {
  NONE = 'none',
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  DELETED = 'deleted'
}

export const EPaymentOptions = [
  { text: 'Monthly', value: 'monthly' },
  { text: 'All at once', value: 'all' },
];

export enum EConsultationStatus {
  SCHEDULED = 'scheduled',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  NO_SHOW = 'no_show'
}

export enum EBloodTest {
  AWAITING_SAMPLE_AT_LAB = 'awaiting_sample_at_lab',
  PROCESSING = 'processing_sample',
  RESULTS_READY = 'results_ready',
  RESULTS_RECEIVED = 'results_received',
  RESULTS_AT_HEALTHCARE_PROVIDER = 'results_at_healthcare_provider'
}

export type TPatient = Patient;
export type TAdmin = Admin;
export type TDoctor = Doctor;
export type TUser = User;

export type TChangePasswordPayload = {
  old_password: string;
  new_password: string;
  new_password_confirmation: string;
};

export enum EFileType {
  PDF = 'pdf'
}

export enum ETaskDue {
  PAST_DUE = 1,
  NOW,
  TODAY,
  TOMORROW,
  LATER
}

export enum ERole {
  DOCTOR = 'doctor',
  ADMIN = 'admin',
  PATIENT = 'patient'
}

export type TAdditionalFile = {
  file: any;
  name: string;
  index?: number;
};

export type TObjectLike<T = string> = Record<string, T>;
