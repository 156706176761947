import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(localizedFormat);

import 'moment/locale/en-gb';
import { convertDate, convertHoursAndMinutes } from '@/composables/useDate';
import { EConsultationStatus } from '@/@types/domain';

export const consultationMapper = (consultations: any) => {
  return consultations.map((consultation: any) => {
    const { starts_at, real_duration, expected_duration, status } = consultation;

    const duration = status === EConsultationStatus.COMPLETED ? real_duration : expected_duration;

    const date = convertDate(starts_at);
    const time = convertHoursAndMinutes(starts_at);
    const title = `Consultation`;
    const newDate = dayjs(starts_at).format('llll');
    const justDate = dayjs(starts_at).format('LL');

    return { ...consultation, date, time, title, newDate, duration };
  });
};
