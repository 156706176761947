import { defineStore } from 'pinia';
import type {
  IActions,
  TGetters,
  TQuizAnswer,
  TQuizInfo,
  TState,
  TAnswer,
  TQuizPayload,
  TQuizPart
} from './quiz.domain';
import {
  EGender,
  EStep,
  initQuizState,
  mapSlideOptions,
  mapSlideNames,
  PARTS,
  EPart,
  slidesWithoutUpdateQuiz
} from './quiz.domain';
import { ONE_WEEK } from '@/constans/time';
import storage from '@/core/storage/BrowserStorage';
import { quizService } from './quiz.service';
import cloneDeep from 'lodash/cloneDeep';
import { bus } from '@/core/bus/EventEmitter';
import {useNotificationStore} from "@/stores/Notification/NotificationStore";
import {TBootOptions} from '@/core/intercom/intercom.domain';
import {intercomHandler} from '@/core/intercom/intercom.handler';
const useQuizStore = defineStore<'quiz', TState, TGetters, IActions>('quiz', {
  state: () => initQuizState(),

  getters: {
    gender(): EGender {
      const { gender } = this.currentQuizData || {};

      if (!gender) {
        return EGender.NONE;
      }

      if (gender === 'female') {
        return EGender.FEMALE;
      }

      return EGender.MALE;
    },

    quizLength(): number {
      return this.quizOrder.length;
    },

    quizId(): number | null {
      return this.currentQuizData?.id || null;
    },

    quizEmailError(): string | null {
      return this.currentQuizData?.emailError || null;
    },

    answers(): TAnswer[] {
      return this.currentQuizData?.answers || [];
    },

    quizOrder(): EStep[] {
      const parts = cloneDeep(PARTS);
      if (this.gender === EGender.FEMALE) {
        delete parts[EPart.MALE];
      }

      if (this.gender === EGender.MALE) {
        delete parts[EPart.FEMALE];
      }

      const partsToFlat = Object.values(parts).flat();
      if (!this.currentQuizData) {
        return partsToFlat;
      }

      const slidesToDelete = [];

      if (this.currentQuizData?.finger_prick_ok !== mapSlideOptions[EStep.FINGER_PRICK_QUESTION].PREFER_NOT_TO) {
        slidesToDelete.push(EStep.DISAGREE_TO_FINGER_PRICK);
      } else {
        slidesToDelete.push(EStep.AGREE_TO_FINGER_PRICK);
      }

      if (this.currentQuizData?.age_range !== mapSlideOptions[EStep.AGE_RANGE]['20-30']) {
        slidesToDelete.push(
          ...[EStep.HORMONAL_FACT, EStep.HAVE_YOU_BEEN_DIAGNOSED_WITH_ANY, EStep.HARMON_NOT_SUITABLE]
        );
      }

      const estrogenProblems =
        this.answers.find((answer) => answer.question === EStep.ANY_SYMPTOMS_OF_ESTROGEN_DEFICIENCY)?.data || {};
      // @ts-ignore
      if (estrogenProblems && Object.values(estrogenProblems).every((value) => value.length === 0)) {
        slidesToDelete.push(EStep.HARMON_CAN_HELP_FACT_FEMALE);
      }

      const testosteroneProblem =
        this.answers.find((answer) => answer.question === EStep.ANY_SYMPTOMS_OF_TESTOSTERONE_DEFICIENCY)?.data || {};

      // @ts-ignore
      if (testosteroneProblem && Object.values(testosteroneProblem).every((value) => value.length === 0)) {
        slidesToDelete.push(EStep.HARMON_CAN_HELP_FACT_MALE);
      }

      const withAnyAnswer = this.answers.find((answer) => answer.question === EStep.HAVE_YOU_BEEN_DIAGNOSED_WITH_ANY)
        ?.data;

      if (withAnyAnswer && withAnyAnswer !== mapSlideOptions[EStep.HAVE_YOU_BEEN_DIAGNOSED_WITH_ANY].NONE) {
        slidesToDelete.push(EStep.HARMON_NOT_SUITABLE);
      }

      slidesToDelete.forEach((slide) => {
        const index = partsToFlat.findIndex((part) => part === slide);
        partsToFlat.splice(index, 1);
      });

      if (!partsToFlat.includes(EStep.EMAIL)) {
        partsToFlat.push(EStep.EMAIL);
      }

      if (!partsToFlat.includes(EStep.FINAL_QUIZ_PAGE)) {
        partsToFlat.push(EStep.FINAL_QUIZ_PAGE);
      }

      return partsToFlat;
    },

    nextSlide(): EStep {
      const currentSlideIndex = this.quizOrder.indexOf(this.currentQuizStep);
      const nextSlideIndex = currentSlideIndex + 1;
      return this.quizOrder[nextSlideIndex];
    },

    previousSlide(): EStep {
      const currentSlideIndex = this.quizOrder.indexOf(this.currentQuizStep);
      const previousSlideIndex = currentSlideIndex - 1;
      return this.quizOrder[previousSlideIndex];
    },

    quizParts(): TQuizPart {
      let parts = cloneDeep(PARTS) as TQuizPart;
      if (this.gender === EGender.FEMALE) {
        delete parts[EPart.MALE];
      }

      if (this.gender === EGender.MALE) {
        delete parts[EPart.FEMALE];
      }

      parts = Object.values(parts).reduce(
        (acc, cur, index) => {
          acc[index + 1] = cur;
          return acc;
        },
        {} as { [key: number]: EStep[] }
      );

      return parts;
    },

    currentPart(): EPart {
      for (const part in this.quizParts) {
        if (this.quizParts[+part as unknown as EPart].includes(this.currentQuizStep)) {
          return +part as unknown as EPart;
        }
      }
    }
  },

  actions: {
    async onShareEmail(email: string, message?: string | undefined): Promise<void> {
      await quizService.shareEmail(this.quizId, email, message);
    },
    async onGetQuizById(id: number): Promise<void> {
      try {
        this.currentQuizData = await quizService.read(id);
      } catch (err) {
        await this.onErrorQuiz();
      }
    },

    async onErrorQuiz(): Promise<void> {
      this.onReset();
      await this.onCreateQuiz();
      this.setQuizInfo(EStep.EMAIL);
      // this.setQuizInfo(EStep.WHAT_BRINGS_YOU_TO_HARMON);
    },

    async onUpdateQuiz(nextSlide?: EStep): Promise<void> {
      let nextSlideToSet = nextSlide || this.nextSlide;
      if (slidesWithoutUpdateQuiz.includes(this.currentQuizStep)) {
        this.setQuizInfo(nextSlideToSet);
        return;
      }

      const question: EStep = mapSlideNames[this.currentQuizStep] || this.currentQuizStep;
      const payload: TQuizPayload = { question, data: this.currentAnswer };
      /*First we crate the Quiz */
      if (payload.question === EStep.EMAIL) {
        await this.onCreateQuiz();
      }

      if (payload.question === EStep.FIRST_NAME) {
        this.currentQuizData.first_name = this.currentAnswer as string;
      }

      try {
        this.currentQuizData = await quizService.update(this.quizId, payload);

        if(this.currentQuizData.existing_quiz_user) {
          this.currentQuizStep = this.currentQuizData.last_completed_question;
          nextSlideToSet = this.nextSlide;
        }

        if (payload.question === EStep.EMAIL) {
          // await this.onFinishQuiz();
          const email = this.currentQuizData.email;
          const first_name = this.currentQuizData.first_name;

          const bootIntercomPayload: TBootOptions = {
            name: `${first_name}`,
            email,
            created_at: Math.round(new Date().getTime() / 1000)
          };

          if (intercomHandler.isBooted) {
            intercomHandler.shutdown();
          }
          if(email){
            intercomHandler.bootQuizGuest(bootIntercomPayload);
          } else {
            intercomHandler.bootGuest();
          }
        }
        if (!nextSlideToSet) {
          return;
        }

        this.setQuizInfo(nextSlideToSet);
      } catch (err) {
        console.log({ err });
        const { addNotification } = useNotificationStore();
        // @ts-ignore
        const errMessage = err.response?.data?.errors?.email?.[0];
        if (errMessage) {
          this.currentQuizData.emailError = errMessage;
          // addNotification({ type: 'error', text: errMessages, title: '' });
        } else {
          await this.onErrorQuiz();
        }
      }
    },

    async onFinishQuiz(): Promise<void> {
      try {
        await quizService.finish(this.quizId);
        bus.emit('quiz:finish');
      } catch(err) {
        console.log(err);
      }
    },

    resetAnswer(): void {
      this.currentAnswer = '';
    },
    resetEmailError(): void {
      this.currentQuizData.emailError = null;
    },

    setCurrentAnswer(answer: TQuizAnswer): void {
      this.currentAnswer = answer;
    },

    async onGetQuizInfo(isQuizPage: boolean = true): Promise<void> {
      const quizInfo = storage.get<TQuizInfo>('quizInfo', 'quiz');

      if (quizInfo && quizInfo.id) {
        const { quizStep, id } = quizInfo;
        this.currentQuizStep = quizStep;
        await this.onGetQuizById(id);
        return;
      }

      if (!isQuizPage) {
        return;
      }

      await this.onCreateQuiz();
      this.setQuizInfo(EStep.EMAIL);
    },

    async onCreateQuiz(): Promise<void> {
      this.currentQuizData = await quizService.create();
    },

    setQuizInfo(quizStep: EStep): void {
      this.currentQuizStep = quizStep;
      const payload: TQuizInfo = { quizStep, id: this.currentQuizData?.id || null };
      storage.set<TQuizInfo>('quizInfo', 'quiz', null, payload, ONE_WEEK);
    },

    async onStartOver(): Promise<void> {
      this.onReset();
      await this.onCreateQuiz();
      this.setQuizInfo(EStep.EMAIL);
    },

    onReset(): void {
      this.$reset();
      storage.remove('quizInfo', 'quiz');
    }
  }
});

export { useQuizStore };
