import { TRouterGuard } from '@/@types/router';
import { getMe } from '@/composables/useAuth';
import { TUserStore } from '@/stores/User/Domain';
import { useUser } from '@/composables/useUser';
import { intercomHandler } from '@/core/intercom/intercom.handler';

export default async function (guard: TRouterGuard, store: TUserStore) {
  try {
    await getMe();
  } catch (error) {
    console.log(error);
  }

  const showIntercomToUser = [store.isDoctor, store.isPatient].some((role) => role);
  if (showIntercomToUser) {
    intercomHandler.show();
  }

  const { to, next } = guard;

  // @ts-ignore
  if (to.meta?.middleware?.length) {
    // @ts-ignore
    to.meta.middleware.forEach((roleMiddleware) => {
      roleMiddleware(guard, store);
    });
    return;
  }

  if (to.path === '/') {
    const name = useUser().userProfileLink.value;
    next({ name });
    return;
  }

  next();
}
