import { defineStore } from 'pinia';
import { axiosInstance as axios } from '@/core/http/axiosInstance';

const useSubscriptionStore = defineStore('subscription', {
  state: () => ({
    subscription: null,
    isLoading: false
  }),

  getters: {
    subscriptionInstance() {
      return this.subscription;
    },
    isCancelled() {
      return this.subscription?.status === 'cancelled';
    },
    isActive() {
      return this.subscription?.status === 'active';
    },
    isPaused() {
      return this.subscription?.status === 'paused';
    }
  },

  actions: {
    decrementConsultationsCredits() {
      this.subscription.consultations_credits = this.subscription.consultations_credits - 1;
    },
    async getSubscription() {
      this.isLoading = true;
      try {
        const { data } = await axios.get('api/patient/settings/subscription');
        this.subscription = data.data;
      } catch (error) {}
      this.isLoading = false;
    },
    async switchBillingModel(model) {
      this.isLoading = true;
      try {
        await axios.put(`api/patient/settings/billing-model/${model}`);
        await this.getSubscription();
      } catch (error) {}
      this.isLoading = false;
    },
    async getBillingCycles() {
      // this.isLoading = true;
      try {
        const { data } = await axios.get(`api/patient/settings/billing-cycles`);
        return data.data;
      } catch (error) {}
      // this.isLoading = false;
    },
    async getSubscriptionCancelReasons() {
      // this.isLoading = true;
      try {
        const { data } = await axios.get(`api/get-subscription-cancel-reasons`);
        return data.data;
      } catch (error) {}
      // this.isLoading = false;
    },

    async cancel(reason, reason_details) {
      const additionalData = {
        reason_detail: reason_details,
        reason_id: reason
      };
      this.isLoading = true;
      await axios.delete(`api/patient/settings/subscription`, {
        data: additionalData
      });
      await this.getSubscription();
      this.isLoading = false;
    }
  }
});

export { useSubscriptionStore };
