import { EHttpStatus } from './core/http/Domain';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import '@/assets/css/style.css';
import { createPinia } from 'pinia';
import { globalComponents } from './core/GlobalComponents';
import ClickOutside from '@/helpers/directives/ClickOutside';
import focus from '@/helpers/directives/focus';
import { useNotificationStore } from './stores/Notification/NotificationStore';
import { AxiosError } from 'axios';
import Vue3TouchEvents from 'vue3-touch-events';
import { createGtm } from '@gtm-support/vue-gtm';
const pinia = createPinia();
const app = createApp(App);

app
  .use(pinia)
  .use(router)
  .use(
    createGtm({
      id: process.env.VUE_APP_GOGGLE_TAG_MANAGER_ID,
      debug: process.env.NODE_ENV === 'development',
      loadScript: true,
      vueRouter: router
    })
  )
  .mount('#app');

globalComponents.forEach(({ name, component }) => {
  app.component(name, component);
});

app.use(Vue3TouchEvents);

app.directive('click-outside', ClickOutside);
app.directive('focus', focus);

app.config.errorHandler = (err) => {
  const { addNotification } = useNotificationStore();
  const isAxiosError = err instanceof AxiosError;
  console.warn({ err });
  if (!isAxiosError) {
    return;
  }

  const { status } = err.response || {};
  if (status === EHttpStatus.UNAUTHORIZED) {
    router.push({ name: 'sign-in' });
    return;
  }

  if ([EHttpStatus.NOT_FOUND].includes(status)) {
    router.push({ name: 'not-found' });
    return;
  }

  if ([EHttpStatus.SERVER_ERROR, EHttpStatus.UNPROCESSABLE_ENTITY].includes(status)) {
    return;
  }

  if (status === EHttpStatus.UNAUTHORIZED && err.response.statusText === 'Unauthorized') {
    return;
  }

  addNotification({ type: 'error', title: err.message, text: err.response?.data?.message });
};
