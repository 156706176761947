<template>
  <div>
    <svg
      v-if="!large"
      class="animate-spin duration-200"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7.99995" cy="7.99995" r="6.99995" stroke="#9782C4" stroke-width="2" />
      <path
        d="M14.9999 7.99995C14.9999 4.13398 11.8659 1 7.99994 1C6.13149 1 4.43403 1.73205 3.17871 2.92499"
        stroke="#E8E2F3"
        stroke-width="2"
      />
    </svg>
    <svg
      v-if="large"
      class="animate-spin duration-200 text-red-700"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9" cy="9" r="8" stroke="#9782C4" stroke-width="2" />
      <path
        d="M16.9993 9C16.9993 4.58172 13.4176 1 8.99927 1C6.86389 1 4.92392 1.83664 3.48926 3.2"
        stroke="#E8E2F3"
        stroke-width="2"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    large: {
      type: Boolean,
      default: false
    }
  }
};
</script>
