import { RouteRecordRaw } from 'vue-router';
import patient from '@/middleware/patient';
import { verifySubscriptionNotCancelled, verifySubscriptionIsActive } from '@/middleware/verifies-subscription-status';
import canScheduleConsultation from '@/middleware/can-schedule-consultation';

const routes: RouteRecordRaw[] = [
  // Consultation
  {
    path: '/patient/consultations/start',
    name: 'start-schedule-consultation',
    beforeEnter: [verifySubscriptionNotCancelled, canScheduleConsultation],
    component: () =>
      import(/* webpackChunkName: "start-schedule-consultation" */ '@/views/Patient/StartScheduleView.vue')
  },
  {
    path: '/patient/consultations/create',
    name: 'create-consultation',
    beforeEnter: [verifySubscriptionNotCancelled, canScheduleConsultation],
    component: () => import(/* webpackChunkName: "create-consultation" */ '@/views/Patient/ConsultationCreateView.vue')
  },
  {
    path: '/patient/consultations/schedule',
    name: 'schedule-consultation',
    beforeEnter: [verifySubscriptionNotCancelled, canScheduleConsultation],
    component: () =>
      import(/* webpackChunkName: "create-consultation" */ '@/views/Patient/ConsultationScheduleTimeView.vue')
  },
  {
    path: '/patient/consultations/confirm',
    name: 'confirm-consultation',
    beforeEnter: [verifySubscriptionNotCancelled, canScheduleConsultation],
    component: () =>
      import(/* webpackChunkName: "confirm-consultation" */ '@/views/Patient/ConsultationConfirmDateView.vue')
  },
  {
    path: '/patient/consultations/scheduled',
    name: 'consultation-scheduled-confirmation',
    beforeEnter: [verifySubscriptionNotCancelled],
    component: () =>
      import(
        /* webpackChunkName: "consultation-scheduled-confirmation" */ '@/views/Patient/ConsultationScheduledConfirmationView.vue'
      )
  },
  {
    path: '/patient/consultations/:id/edit',
    name: 'edit-consultation',
    beforeEnter: [verifySubscriptionNotCancelled],
    component: () => import(/* webpackChunkName: "edit-consultation" */ '@/views/Patient/EditConsultationView.vue')
  },
  // Account
  {
    path: '/patient/account/delivery-address',
    name: 'patient-account-delivery-address',
    component: () =>
      import(
        /* webpackChunkName: "patient-account-delivery-address" */ '@/views/Patient/Account/DeliveryAddressView.vue'
      )
  },
  {
    path: '/patient/account/payment-methods',
    name: 'patient-account-payment-methods',
    component: () =>
      import(/* webpackChunkName: "patient-account-payment-methods" */ '@/views/Patient/Account/PaymentMethodsView.vue')
  },
  {
    path: '/patient/account/subscription-settings',
    name: 'patient-account-subscription-settings',
    component: () =>
      import(
        /* webpackChunkName: "patient-account-payment-methods" */ '@/views/Patient/Account/SubscriptionSettings.vue'
      )
  },
  {
    path: '/patient/account/payment-methods-processing/',
    name: 'patient-account-payment-methods-processing',
    component: () =>
      import(
        /* webpackChunkName: "patient-account-payment-methods-processing" */ '@/views/Patient/Account/PaymentMethodUpdateProcessingView.vue'
      )
  },
  {
    path: '/patient/account/payment-methods/:result',
    name: 'patient-account-payment-methods-result',
    component: () =>
      import(
        /* webpackChunkName: "patient-account-payment-methods-result" */ '@/views/Patient/Account/PaymentMethodUpdateResultView.vue'
      )
  },
  {
    path: '/patient/account/email-address',
    name: 'patient-account-email-address',
    component: () =>
      import(/* webpackChunkName: "patient-account-email-address" */ '@/views/Patient/Account/EmailAddressView.vue')
  },
  {
    path: '/patient/account/password',
    name: 'patient-account-password',
    component: () =>
      import(/* webpackChunkName: "patient-account-password" */ '@/views/Patient/Account/PasswordView.vue')
  },
  {
    path: '/patient/account/notifications',
    name: 'patient-account-notifications',
    component: () =>
      import(/* webpackChunkName: "patient-account-notifications" */ '@/views/Patient/Account/NotificationView.vue')
  },
  {
    path: '/patient/account/remove-account',
    name: 'patient-account-remove-account',
    component: () =>
      import(/* webpackChunkName: "patient-account-remove-account" */ '@/views/Patient/Account/RemoveAccountView.vue')
  },
  {
    path: '/patient/account/activity',
    name: 'patient-account-activity',
    component: () =>
      import(/* webpackChunkName: "patient-account-activity" */ '@/views/Patient/Account/ActivityView.vue')
  },
  {
    path: '/patient/account/transactions',
    name: 'patient-account-transactions',
    component: () =>
      import(/* webpackChunkName: "patient-account-transactions" */ '@/views/Patient/Account/TransactionsView.vue')
  },
  {
    path: '/patient/inbox',
    name: 'patient-inbox',
    component: () => import(/* webpackChunkName: "patient-inbox" */ '@/views/Patient/InboxView.vue')
  },
  {
    path: '/patient/promotions',
    name: 'patient-promotions',
    component: () => import(/* webpackChunkName: "patient-promotion" */ '@/views/Patient/PromotionView.vue')
  },
  {
    path: '/patient/consultations/:id/results',
    name: 'patient-id-consultation-results',
    component: () =>
      import(/* webpackChunkName: "patient-id-consultation-results" */ '@/views/Patient/ConsultationResultView.vue')
  },
  {
    path: '/patient/consultations/:id/:meetingTitle?',
    name: 'patient-id-consultation',
    beforeEnter: [verifySubscriptionNotCancelled],
    component: () => import(/* webpackChunkName: "patient-id-consultation" */ '../views/Patient/ConsultationView.vue')
  },
  {
    path: '/patient/consultations',
    name: 'patient-consultations',
    component: () => import(/* webpackChunkName: "patient-consultations" */ '@/views/Patient/ConsultationsView.vue')
  },
  {
    path: '/patient/confirmation',
    name: 'confirmation',
    beforeEnter: [verifySubscriptionNotCancelled],
    component: () => import(/* webpackChunkName: "confirmation" */ '@/views/Patient/ConfirmationView.vue')
  },
  {
    path: '/patient/documents',
    name: 'patient-documents',
    component: () => import(/* webpackChunkName: "patient-documents" */ '@/views/Patient/TasksView.vue')
  },
  {
    path: '/patient/documents/id-check',
    name: 'patient-documents-id-check',
    component: () =>
      import(/* webpackChunkName: "patient-documents-id-check" */ '@/views/Patient/Tasks/IdCheckView.vue')
  },
  {
    path: '/patient/documents/additional',
    name: 'patient-documents-additional',
    component: () =>
      import(/* webpackChunkName: "patient-documents-id-check" */ '@/views/Patient/Tasks/AdditionalDocumentsView.vue')
  },
  {
    path: '/patient/dashboard',
    name: 'patient-dashboard',
    component: () => import(/* webpackChunkName: "patient-dashboard" */ '../views/Patient/DashboardView.vue')
  },
  {
    path: '/patient/notifications-settings',
    name: 'patient-notifications-settings',
    component: () =>
      import(
        /* webpackChunkName: "patient-notifications-settings" */ '@/views/Patient/Account/NotificationsSettingsView.vue'
      )
  },
  {
    path: '/patient/prescriptions',
    name: 'patient-prescriptions',
    component: () =>
      import(/* webpackChunkName: "patient-prescriptions" */ '@/views/Patient/PatientPrescriptionsView.vue')
  },
  {
    path: '/patient/prescriptions/:prescriptionId',
    name: 'patient-prescription-id',
    component: () =>
      import(/* webpackChunkName: "patient-prescriptions" */ '@/views/Patient/PatientPrescriptionView.vue')
  },
  {
    path: '/patient/blood-tests',
    name: 'blood-tests',
    component: () => import(/* webpackChunkName: "blood-tests" */ '@/views/Patient/BloodTests.vue')
  },
  {
    path: '/patient/blood-tests/create',
    name: 'blood-tests-create',
    beforeEnter: [verifySubscriptionIsActive],
    component: () => import(/* webpackChunkName: "blood-tests" */ '@/views/Patient/BloodTestCreate.vue')
  },
  {
    path: '/patient/blood-tests/:bloodTestId',
    name: 'blood-tests-id',
    component: () => import(/* webpackChunkName: "blood-tests-id" */ '@/views/Patient/BloodTestView.vue')
  },
  {
    path: '/patient/initial-blood-test',
    name: 'patient-initial-blood-test',
    component: () =>
      import(/* webpackChunkName: "patient-initial-blood-tests" */ '@/views/Patient/InitialBloodTestView.vue')
  },

  {
    path: '/patient/account',
    name: 'patient-account',
    component: () => import(/* webpackChunkName: "about" */ '../views/Patient/AccountView.vue')
  }
];

routes.forEach((route) => {
  const middleware: [] = (route.meta?.middleware as []) || [];
  route.meta = { ...route.meta, middleware: [patient, ...middleware] };
});

export default routes;
