import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { usePatientStore } from '@/stores/Patient/patient.store';
import { useSubscriptionStore } from '@/stores/Subscription/subscription-store';

export default function (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  const patient = usePatientStore();
  const subscription = useSubscriptionStore();

  if (
    (patient.patientEntity.completed_consultations_count === 0 && !patient.initialConsultation) ||
    (subscription.isActive && !['scheduled', 'in_progress'].includes(patient.initialConsultation?.status))
  ) {
    next();
    return;
  }

  next({ name: 'patient-consultations', replace: true });
}
