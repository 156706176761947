<template>
  <span
    class="inline-flex items-center space-x-1 rounded-full py-1 px-2 font-mono text-app-p-xsm font-medium uppercase"
  >
    <span>
      <slot></slot>
    </span>
    <slot name="icon"></slot>
  </span>
</template>
