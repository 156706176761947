import type { RouteRecordRaw } from 'vue-router';
import doctor from '@/middleware/doctor';

const doctorRoutes: RouteRecordRaw[] = [
  {
    path: '/doctor/dashboard',
    name: 'doctor-dashboard',
    component: () => import(/* webpackChunkName: "doctor-dashboard" */ '../views/Doctor/DashboardView.vue')
  },
  {
    path: '/doctor/patient/:patientId',
    name: 'doctor-patient-id',
    component: () => import(/* webpackChunkName: "doctor-patient-id" */ '../views/Doctor/PatientView.vue')
  },
  {
    path: '/doctor/patient/:patientId/consultations',
    name: 'doctor-patient-id-consultations',
    component: () => import(/* webpackChunkName: "doctor-consultations-id" */ '../views/Doctor/ConsultationsView.vue')
  },
  {
    path: '/doctor/patient/:patientId/consultations/:consultationId',
    name: 'doctor-patient-id-consultations-consultation-id',
    component: () => import(/* webpackChunkName: "doctor-consultations-id" */ '../views/Doctor/ConsultationView.vue')
  },
  {
    path: '/doctor/patient/:patientId/prescriptions',
    name: 'doctor-patient-id-prescriptions',
    component: () =>
      import(/* webpackChunkName: "doctor-patient-id-prescriptions" */ '../views/Doctor/PrescriptionsView.vue')
  },
  {
    path: '/doctor/patient/:patientId/prescriptions/:prescriptionId',
    name: 'doctor-patient-id-prescriptions-id',
    component: () =>
      import(/* webpackChunkName: "doctor-patient-id-prescriptions-id" */ '../views/Doctor/PrescriptionView.vue')
  },
  {
    path: '/doctor/patient/:patientId/prescriptions/create',
    name: 'doctor-patient-id-create-prescription',
    component: () =>
      import(/* webpackChunkName: "doctor-create-prescription-id" */ '../views/Doctor/PrescriptionCreateView.vue')
  },
  {
    path: '/doctor/consultation/:id/summary',
    name: 'doctor-consultation-id-summary',
    component: () =>
      import(/* webpackChunkName: "doctor-consultation-id-summary" */ '@/views/Doctor/ConsultationSummaryView.vue')
  },
  {
    path: '/doctor/patient/:patientId/blood-tests',
    name: 'doctor-patient-id-blood-tests',
    component: () =>
      import(/* webpackChunkName: "doctor-patient-id-blood-tests" */ '../views/Doctor/BloodTests/BloodTestsView.vue')
  },
  {
    path: '/doctor/patient/:patientId/blood-tests/:bloodTestId',
    name: 'doctor-patient-id-blood-tests-id',
    component: () =>
      import(/* webpackChunkName: "doctor-blood-tests-id" */ '../views/Doctor/BloodTests/BloodTestView.vue')
  },
  {
    path: '/doctor/account',
    name: 'doctor-account',
    component: () => import(/* webpackChunkName: "doctor-account" */ '../views/Doctor/Account/AccountView.vue')
  },
  {
    path: '/doctor/account/email-address',
    name: 'doctor-account-email-address',
    component: () =>
      import(
        /* webpackChunkName: "doctor-account-email-address" */ '../views/Doctor/Account/DoctorEmailAddressView.vue'
      )
  },
  {
    path: '/doctor/account/password',
    name: 'doctor-account-password',
    component: () =>
      import(/* webpackChunkName: "doctor-account-password" */ '../views/Doctor/Account/DoctorPasswordView.vue')
  },
  {
    path: '/doctor/account/activity',
    name: 'doctor-account-activity',
    component: () =>
      import(
        /* webpackChunkName: "doctor-account-activity" */ '../views/Doctor/Account/DoctorActivitiesHistoryView.vue'
      )
  },
  {
    path: '/doctor/patient/:patientId/quiz-results',
    name: 'doctor-patient-id-quiz-results',
    component: () =>
      import(/* webpackChunkName: "doctor-patient-id-quiz-results" */ '../views/Doctor/PatientQuizResultsView.vue')
  }
];

doctorRoutes.forEach((route) => {
  const middleware: [] = (route.meta?.middleware as []) || [];
  route.meta = { ...route.meta, middleware: [doctor, ...middleware] };
});

export default doctorRoutes;
