import type { TNotification } from '@/@types/components';
import { TFetchState } from '../core/Domain';

export type TState = TFetchState & {
  notifications: TNotification[];
};

export type TGetters = {
  globalNotices(): TNotification[];
};

export type IActions = {
  addNotification(payload: TNotification): void;
  removeNotification(payload: TNotification): void;
};

export const initNotificationState = (): TState => {
  return {
    isLoading: false,
    isError: false,
    notifications: []
  };
};
