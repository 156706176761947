import HButton from '@/components/UI/HButton.vue';
import HInput from '@/components/UI/HInput.vue';
import HToggle from '@/components/UI/HToggle.vue';
import HBackButton from '@/components/UI/HBackButton.vue';
import HTag from '@/components/UI/HTag.vue';
import HNextButton from '@/components/UI/HNextButton.vue';
import HContainer from '@/components/UI/HContainer.vue';
import HLightGreenButton from '@/components/UI/HLightGreenButton.vue';
import HCloseButton from '@/components/UI/HCloseButton.vue';
import HSteps from '@/components/UI/Steps/HSteps.vue';
import HTextarea from '@/components/UI/HTextarea.vue';
import HSelect from '@/components/UI/HSelect.vue';
import HSpinner from '@/components/UI/HSpinner.vue';
import HDatePicker from '@/components/UI/HDatePicker.vue';
import HNotification from '@/components/UI/HNotification.vue';
import HModal from '@/components/UI/HModal.vue';
import HLoading from '@/components/UI/HLoading.vue';
import HCheckbox from '@/components/UI/HCheckbox.vue';
import HHeading from '@/components/UI/HHeading.vue';
import HSubheading from '@/components/UI/HSubheading.vue';
import MobileView from '@/components/PageLayouts/MobileView.vue';
import HContentSkeleton from '@/components/UI/skeletons/HContentSkeleton.vue';
import HBaseContainerSkeleton from '@/components/UI/skeletons/HBaseContainerSkeleton.vue';
import HarmonTag from '@/components/UI/Tags/HarmonTag.vue';
import IdTag from '@/components/UI/Tags/IdTag.vue';
import HContainerSkeleton from '@/components/UI/skeletons/HContainerSkeleton.vue';
import HBlockTitle from '@/components/UI/HBlockTitle.vue';
import AsyncDataViewer from '@/components/PageLayouts/AsyncDataViewer.vue';
import HTabs from '@/components/UI/Tabs/HTabs.vue';
import HTab from '@/components/UI/Tabs/HTab.vue';
import HTooltip from '@/components/UI/HTooltip.vue';
import QuizCounter from '@/components/quiz/QuizCounter.vue';
import HQuizHeading from '@/components/UI/HQuizHeading.vue';
import BackQuizButton from '@/components/quiz/BackQuizButton.vue';
import InputError from '@/components/UI/InputError.vue';

import type { TGlobalComponent } from '@/@types/components';

export const globalComponents: TGlobalComponent[] = [
  { name: 'HButton', component: HButton },
  { name: 'HInput', component: HInput },
  { name: 'HToggle', component: HToggle },
  { name: 'HBackButton', component: HBackButton },
  { name: 'HNextButton', component: HNextButton },
  { name: 'HTag', component: HTag },
  { name: 'HContainer', component: HContainer },
  { name: 'HLightGreenButton', component: HLightGreenButton },
  { name: 'HCloseButton', component: HCloseButton },
  { name: 'HSteps', component: HSteps },
  { name: 'HTextarea', component: HTextarea },
  { name: 'HSelect', component: HSelect },
  { name: 'HSpinner', component: HSpinner },
  { name: 'HDatePicker', component: HDatePicker },
  { name: 'HNotification', component: HNotification },
  { name: 'HModal', component: HModal },
  { name: 'HLoading', component: HLoading },
  { name: 'HCheckbox', component: HCheckbox },
  { name: 'HHeading', component: HHeading },
  { name: 'HSubheading', component: HSubheading },
  { name: 'MobileView', component: MobileView },
  { name: 'HContentSkeleton', component: HContentSkeleton },
  { name: 'HBaseContainerSkeleton', component: HBaseContainerSkeleton },
  { name: 'HContainerSkeleton', component: HContainerSkeleton },
  { name: 'HarmonTag', component: HarmonTag },
  { name: 'IdTag', component: IdTag },
  { name: 'AsyncDataViewer', component: AsyncDataViewer },
  { name: 'HBlockTitle', component: HBlockTitle },
  { name: 'HTabs', component: HTabs },
  { name: 'HTab', component: HTab },
  { name: 'HTooltip', component: HTooltip },
  { name: 'QuizCounter', component: QuizCounter },
  { name: 'HQuizHeading', component: HQuizHeading },
  { name: 'BackQuizButton', component: BackQuizButton },
  { name: 'InputError', component: InputError }
];
