import { TPatient } from './../Patient/patient.domain';
import { IApi, useApi } from '@/composables/useApi';
import { axiosInstance } from '@/core/http/axiosInstance';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import type { IDoctorService, TBloodTest, TConsultation, TSummaryPayload, TDoctorTasks } from './doctor.domain';
import TryCatch from '@/core/decorators/TryCatch';

import { PatientCollection } from '@/Api/Api';
class DoctorService implements IDoctorService {
  private readonly axios: AxiosInstance;
  private readonly swagger: IApi;

  constructor() {
    this.axios = axiosInstance;
    this.swagger = useApi().getApiInstance();
  }

  @TryCatch()
  public async completeTask(taskId: number): Promise<void> {
    await this.axios.post(`api/doctor/completed-tasks/${taskId}`);
  }

  @TryCatch()
  public async readTasks(): Promise<TDoctorTasks> {
    const { data } = await this.axios.get(`api/doctor/tasks`);
    return data.data;
  }

  @TryCatch()
  public async readConsultation(consultationId: string): Promise<any> {
    const { data } = await this.axios.get(`api/doctor/consultations/${consultationId}`);
    return data.data;
  }

  /**
   * @deprecated
   */
  @TryCatch()
  public async leaveConsultationSummary(payload: TSummaryPayload): Promise<any> {
    const { consultationId, summary } = payload;

    const { data } = await this.axios.put(`api/doctor/consultations/${consultationId}`, { doctor_notes: summary });
    return data.data;
  }

  @TryCatch()
  public async finishConsultation(consultationId: number): Promise<void> {
    await this.axios.post(`api/doctor/completed-consultations/${consultationId}`);
  }

  public async readBloodTests(patientId: number): Promise<TBloodTest[]> {
    const { data } = await this.axios.get(`api/doctor/patients/${patientId}/blood-tests`);
    return data.data;
  }

  @TryCatch()
  public async readBloodTest(patientId: number, testId: number): Promise<TBloodTest> {
    const { data } = await this.axios.get(`api/doctor/patients/${patientId}/blood-tests/${testId}`);
    return data.data;
  }

  @TryCatch()
  public async readConsultations(patientId: number): Promise<TConsultation[]> {
    const { data } = await this.axios.get(`api/doctor/patients/${patientId}/consultations`);
    return data.data;
  }

  @TryCatch()
  public async readActions(search?: string): Promise<any> {
    const config: AxiosRequestConfig = {};
    const queryParams: AxiosRequestConfig['params'] = {};

    if (search) {
      queryParams.search = search;
      config.params = new URLSearchParams(queryParams);
    }

    const { data } = await this.axios.get(`api/doctor/actions`, config);
    return data.data;
  }

  @TryCatch()
  public async readPatient(id: number): Promise<TPatient> {
    const { data } = await this.axios.get(`api/doctor/patients/${id}`);
    return data.data;
  }

  @TryCatch()
  public async readPatients(search: string = '', page :number = 1): Promise<PatientCollection> {
    const config: AxiosRequestConfig = {};
    const queryParams: AxiosRequestConfig['params'] = { page };

    queryParams.is_paying_customer = true;

    if (search) {
      queryParams.search = search;
    }

    if (Object.keys(queryParams).length !== 0) {
      config.params = new URLSearchParams(queryParams);
    }

    const { data } = await this.axios.get(`api/doctor/patients`, config);
    return data;
  }
}

export const doctorService = new DoctorService();
