import doctor_patient from '@/middleware/doctor_patient';
import { RouteRecordRaw } from 'vue-router';

export const routes: RouteRecordRaw[] = [
  {
    path: '/meetings/:id/in-progress/:meetingId?',
    name: 'meeting-in-progress',
    component: () => import(/* webpackChunkName: "meeting-in-progress" */ '@/views/MeetingInProgressView.vue'),
    meta: {
      middleware: [doctor_patient]
    }
  }
];
