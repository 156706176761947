export enum EMethod {
  BOOT = 'boot',
  SHUTDOWN = 'shutdown',
  UPDATE = 'update',
  TRACK_EVENT = 'trackEvent'
}

export type TBootOptions = {
  name?: string;
  email?: string;
  created_at?: number;
};

export type TBootPayload = {
  api_base: string;
  app_id: string;
} & TBootOptions;

export type TUpdateOptions = {
  hide_default_launcher?: boolean;
} & TBootOptions;

export interface IIntercom {
  update(options: TUpdateOptions): void;
  boot(options: TBootOptions): void;
  bootQuizGuest(options: TBootOptions): void;
  bootGuest(): void;
  hide(): void;
  show(): void;
  shutdown(): void;
  switchToUser(options: TBootOptions): void;
}
