import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import patientRoutes from '@/router/patient';
import { routes as meetingRoutes } from '@/router/meeting';
import doctorRoutes from './doctor';
import adminRoutes from './admin';
import auth from '@/middleware/auth';
import register from '@/middleware/register';
import guest from '@/middleware/guest';
import patient from '@/middleware/patient';

const routes: RouteRecordRaw[] = [
  ...patientRoutes,
  ...meetingRoutes,
  ...doctorRoutes,
  ...adminRoutes,
  {
    path: '/sign-out',
    name: 'sign-out',
    component: () => import(/* webpackChunkName : "sign-out" */ '../views/Authorization/SignOutView.vue'),
    meta: { middleware: [register] }
  },
  /*{
    path: '/sign-up',
    name: 'sign-up',
    component: () => import(/!* webpackChunkName: "sign-up" *!/ '../views/Authorization/SignUpView.vue'),
    meta: { middleware: [register] },
    children: [
      {
        path: '',
        name: 'sign-up-main',
        component: () => import(/!* webpackChunkName: "sign-up-main" *!/ '../views/Authorization/SignUpFirst.vue'),
        meta: { middleware: [register] }
      },
      {
        path: '/sign-up/verify-email',
        name: 'verify-email',
        component: () => import(/!* webpackChunkName: "verify-email" *!/ '../views/Authorization/VerifyEmail.vue'),
        meta: { middleware: [register] }
      },
      {
        path: '/sign-up/details',
        name: 'sign-up-details',
        component: () => import(/!* webpackChunkName: "sign-up-details" *!/ '../views/Authorization/SignUpDetails.vue'),
        meta: { middleware: [register] }
      }
    ]
  },*/
  {
    path: '/sign-in',
    name: 'sign-in',
    component: () => import(/* webpackChunkName: "sign-in"  */ '../views/Authorization/SignInView.vue'),
    meta: { middleware: [guest, register] }
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    component: () => import(/* webpackChunkName: "sign-up"  */ '../views/Patient/Account/SinglePageCheckout.vue'),
    meta: { middleware: [register] }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import(/* webpackChunkName: "forgot-password"  */ '../views/Authorization/ForgotPasswordView.vue'),
    meta: { middleware: [register] }
  },
  {
    path: '/forgot-password/success',
    name: 'forgot-password-success',
    component: () =>
      import(/* webpackChunkName: "forgot-password-success"  */ '../views/Authorization/ForgotPasswordSuccessView.vue'),
    meta: { middleware: [register] }
  },
  {
    path: '/password-reset/:resetPasswordToken?',
    name: 'password-reset',
    component: () => import(/* webpackChunkName: "password-reset"  */ '../views/Authorization/PasswordResetView.vue'),
    meta: { middleware: [register] }
  },

  {
    path: '/cart',
    name: 'cart',
    component: () => import(/* webpackChunkName: "cart" */ '../views/Cart/CartView.vue'),
    meta: {
      middleware: [patient]
    }
  },
  {
    path: '/cart/:result',
    name: 'cart-result',
    component: () => import(/* webpackChunkName: "cart-result" */ '../views/Cart/CartPaymentResult.vue'),
    meta: {
      middleware: [patient]
    }
  },
  {
    path: '/cart/processing',
    name: 'cart-processing',
    component: () => import(/* webpackChunkName: "cart-checkout" */ '../views/Cart/CartProcessing.vue'),
    meta: {
      middleware: [patient]
    }
  },
  { path: '/quiz', name: 'quiz', component: () => import(/* webpackChunkName: "quiz" */ '@/views/QuizView.vue') },
  { path: '/about', name: 'about', component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue') },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFoundView.vue')
  }
];

export const PUBLIC_ROUTES = [
  'sign-up',
  'sign-up-main',
  'sign-in',
  'sign-up-details',
  'quiz',
  'forgot-password',
  'forgot-password-success',
  'password-reset',
  'not-found'
];

routes.forEach((route) => {
  if (PUBLIC_ROUTES.includes(route.name as string)) {
    return;
  }

  const middleware: [] = (route.meta?.middleware as []) || [];
  route.meta = { ...route.meta, middleware: [auth, ...middleware] };
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
