import storage from '@/core/storage/BrowserStorage';
import { useUserStore } from '@/stores/User/UserStore';
import { Ref, ShallowRef } from 'vue';

export const useTabsCache = (tabs: ShallowRef<any[]>, currentTab: Ref<any>, currentIndex: Ref<number>) => {
  const userStore = useUserStore();

  const cacheTab = (index: number): void => {
    const key = getCacheKey();
    storage.set(key, 'tabs', userStore.user?.id, index, 3600);
  };

  const getCacheKey = () => tabs.value.map((tab) => tab.props['target-id']).join('-');

  const setCachedTab = () => {
    const key = getCacheKey();

    const cachedTabIndex = storage.get<number>(key, 'tabs', userStore.user?.id);

    if (cachedTabIndex) {
      const tab = tabs.value[cachedTabIndex];
      if (tab) {
        currentTab.value = tab;
        currentIndex.value = cachedTabIndex;
      } else {
        setDefaultTab();
      }
    } else {
      setDefaultTab();
    }
  };

  const setDefaultTab = () => {
    currentTab.value = tabs.value[0];
  };

  return { setCachedTab, setDefaultTab, cacheTab, getCacheKey };
};
