import { useSubscriptionStore } from '@/stores/Subscription/subscription-store';
import { useUser } from '@/composables/useUser';

export const verifySubscriptionNotCancelled = async (to, from, next) => {
  const subscriptionStore = useSubscriptionStore();
  if (!subscriptionStore.subscriptionInstance) {
    await subscriptionStore.getSubscription();
  }

  if (!subscriptionStore.subscriptionInstance) {
    return;
  }

  if (subscriptionStore.isCancelled) {
    const { userProfileLink } = useUser();
    next({ name: userProfileLink.value });
    return;
  }
  next();
  return;
};

export const verifySubscriptionIsActive = (to, from, next) => {
  const subscriptionStore = useSubscriptionStore();
  if (!subscriptionStore.subscriptionInstance) {
    return;
  }

  if (!subscriptionStore.isActive) {
    const { userProfileLink } = useUser();
    next({ name: userProfileLink.value });
    return;
  }
  next();
  return;
};
