import { TFetchState } from '../core/Domain';

export enum EGender {
  NONE,
  FEMALE = 2,
  MALE
}

export enum EPart {
  START = 1,
  FEMALE,
  MALE,
  FINAL
}

export enum EStep {
  // START
  EMAIL = 'email', // 18
  WHAT_BRINGS_YOU_TO_HARMON = 'what_brings_you_to_harmon', // 1
  AGE_FACT = 'age_fact', // 2
  AGE_RANGE = 'age_range', // 3
  HORMONAL_FACT = 'hormonal_fact', // 4, only if answer is 'yes' 20s-30s
  HAVE_YOU_BEEN_DIAGNOSED_WITH_ANY = 'have_you_been_diagnosed_with_any', // 5
  HARMON_NOT_SUITABLE = 'harmon_not_suitable', // 6 if chosen none of the above (c)
  FIRST_NAME = 'first_name', // 7
  FIRST_NAME_GREETING = 'first_name_greeting', // 8
  GENDER = 'gender', // 9

  ANY_FOLLOWING_MEDICAL_CONDITIONS = 'any_following_medical_conditions', // deprecated

  // FEMALE
  ANY_FOLLOWING_MEDICAL_CONDITIONS_FEMALE = 'any_following_medical_conditions_female', // 10 female
  FEMALE_CONDITIONS_FACT = 'female_conditions_fact', // 11
  PERI_MENOPAUSAL_SYMPTOMS = 'peri_menopausal_symptoms', // 12
  HARMON_CAN_HELP_FACT_FEMALE = 'harmon_can_help_fact_female', // 13 // only if something is chosen, otherwise skip

  // MALE
  ANY_FOLLOWING_MEDICAL_CONDITIONS_MALE = 'any_following_medical_conditions_male', // 10 male
  MALE_CONDITIONS_FACT = 'male_conditions_fact', // 11
  ANY_SYMPTOMS_OF_TESTOSTERONE_DEFICIENCY = 'any_symptoms_of_testosterone_deficiency', // 12
  HARMON_CAN_HELP_FACT_MALE = 'harmon_can_help_fact_male', // 13 // only if something is chosen, otherwise skip

  // FINAL
  LOCATION = 'location', // 14
  FINGER_PRICK_QUESTION = 'finger_prick_question', // 15
  AGREE_TO_FINGER_PRICK = 'agree_to_finger_prick', // 16 // if answers a,b
  DISAGREE_TO_FINGER_PRICK = 'disagree_to_finger_prick', // 17 if c

  // other
  ANY_SYMPTOMS_OF_ESTROGEN_DEFICIENCY = 'any_symptom_of_estrogen_deficiency',
  ANY_SYMPTOM_OF_ESTROGEN_DEFICIENCY = 'any_symptom_of_estrogen_deficiency',
  FINGER_PRICK_OK = 'finger_prick_ok', // 15
  QUIZ_REPORT = 'quiz_report', // 16
  FINAL_QUIZ_PAGE = 'final_quiz_page', // 17

}

export type TQuizAnswer = string | string[];
export type TQuiz = {
  age_range: string;
  answers: [];
  email: string;
  finger_prick_ok: string;
  first_name: string;
  gender: string;
  id: number;
  is_complete: boolean;
  last_completed_question: EStep;
  last_name: string;
  location: string;
  phone: string;
  quiz_complete: string;
  emailError: string;
  signup_source: string;
  pagePath: string;
  pageTitle: string;
  existing_quiz_user: boolean;
};
export type TAnswer = { data: string | string[]; question: string | EStep };
export type TQuizPart = Partial<Record<EPart, EStep[]>>;

export type TQuizOption = {
  id: number;
  title: string;
  value?: EGender | any;
};

export type TQuizPayload = {
  question: EStep;
  data: TQuizAnswer;
};

export type TQuizInfo = {
  quizStep: EStep;
  id: number;
  part?: EPart;
};

export type TState = TFetchState & {
  currentQuizStep: EStep;
  currentAnswer: TQuizAnswer;
  currentQuizData: TQuiz;
};

export type TGetters = {
  quizLength(): number;
  quizId(): number | null;
  quizEmailError(): string | null;
  quizOrder(): EStep[];
  nextSlide(): EStep;
  previousSlide(): EStep;
  quizParts(): TQuizPart;
  currentPart(): EPart;
  answers(): TAnswer[];
  gender(): EGender;
};

export interface IQuizService {
  read(id: number): Promise<TQuiz>;
  create(): Promise<TQuiz>;
  update(id: number, payload: TQuizPayload): Promise<TQuiz>;
  finish(id: number): Promise<void>;
  shareEmail(id: number, email: string, message?: string): Promise<void>;
}

export interface IActions {
  setQuizInfo(quizStep: EStep): void;
  setCurrentAnswer(answer: TQuizAnswer | any): void;
  resetAnswer(): void;
  resetEmailError(): void;
  onReset(): void;

  onShareEmail(email: string, message?: string | undefined): Promise<void>;
  onGetQuizInfo(isQuizPage?: boolean): Promise<void>;
  onGetQuizById(id: number): Promise<void>;
  onCreateQuiz(): Promise<void>;
  onUpdateQuiz(nextSlide?: EStep): Promise<void>;
  onFinishQuiz(): Promise<void>;
  onStartOver(): Promise<void>;
  onErrorQuiz(): Promise<void>;
}

export const initQuizState = (): TState => {
  return {
    isLoading: false,
    isError: false,
    currentQuizStep: EStep.WHAT_BRINGS_YOU_TO_HARMON,
    currentAnswer: '',
    currentQuizData: null
  };
};

export const QUESTIONS_DICTIONARY: { [key: string]: string } = {
  [EStep.WHAT_BRINGS_YOU_TO_HARMON]: 'What brings to Harmon',
  [EStep.HAVE_YOU_BEEN_DIAGNOSED_WITH_ANY]: 'Patient has been diagnosed with',
  [EStep.ANY_FOLLOWING_MEDICAL_CONDITIONS]: 'Following medical conditions',
  [EStep.ANY_SYMPTOM_OF_ESTROGEN_DEFICIENCY]: 'Peri menopausal symptoms',
  [EStep.ANY_SYMPTOMS_OF_TESTOSTERONE_DEFICIENCY]: 'Peri menopausal symptoms'
};

export const PARTS: TQuizPart = {
  [EPart.START]: [
    EStep.EMAIL,
    EStep.WHAT_BRINGS_YOU_TO_HARMON,
    EStep.AGE_FACT,
    EStep.AGE_RANGE,
    EStep.HORMONAL_FACT,
    EStep.HAVE_YOU_BEEN_DIAGNOSED_WITH_ANY,
    EStep.HARMON_NOT_SUITABLE,
    EStep.FIRST_NAME,
    EStep.FIRST_NAME_GREETING,
    EStep.GENDER
  ],
  [EPart.FEMALE]: [
    EStep.ANY_FOLLOWING_MEDICAL_CONDITIONS_FEMALE,
    EStep.FEMALE_CONDITIONS_FACT,
    EStep.PERI_MENOPAUSAL_SYMPTOMS,
    EStep.HARMON_CAN_HELP_FACT_FEMALE
  ],
  [EPart.MALE]: [
    EStep.ANY_FOLLOWING_MEDICAL_CONDITIONS_MALE,
    EStep.MALE_CONDITIONS_FACT,
    EStep.ANY_SYMPTOMS_OF_TESTOSTERONE_DEFICIENCY,
    EStep.HARMON_CAN_HELP_FACT_MALE
  ],
  [EPart.FINAL]: [
    EStep.LOCATION,
    EStep.FINGER_PRICK_QUESTION,
    EStep.AGREE_TO_FINGER_PRICK,
    EStep.DISAGREE_TO_FINGER_PRICK,
    EStep.QUIZ_REPORT,
    EStep.FINAL_QUIZ_PAGE
  ]
};

export const slidesWithoutUpdateQuiz: EStep[] = [
  EStep.AGE_FACT,
  EStep.HORMONAL_FACT,
  EStep.FIRST_NAME_GREETING,
  EStep.HARMON_NOT_SUITABLE,
  EStep.FEMALE_CONDITIONS_FACT,
  EStep.HARMON_CAN_HELP_FACT_FEMALE,
  EStep.MALE_CONDITIONS_FACT,
  EStep.HARMON_CAN_HELP_FACT_MALE,
  EStep.AGREE_TO_FINGER_PRICK,
  EStep.DISAGREE_TO_FINGER_PRICK
];

export const mapSlideNames: Partial<Record<EStep, EStep>> = {
  [EStep.ANY_FOLLOWING_MEDICAL_CONDITIONS_FEMALE]: EStep.ANY_FOLLOWING_MEDICAL_CONDITIONS,
  [EStep.ANY_FOLLOWING_MEDICAL_CONDITIONS_MALE]: EStep.ANY_FOLLOWING_MEDICAL_CONDITIONS,
  [EStep.PERI_MENOPAUSAL_SYMPTOMS]: EStep.ANY_SYMPTOM_OF_ESTROGEN_DEFICIENCY,
  [EStep.FINGER_PRICK_QUESTION]: EStep.FINGER_PRICK_OK
};

export const mapSlideOptions = {
  [EStep.AGE_RANGE]: {
    '20-30': '20s-30s',
    '40-50': '40s-50s',
    '60-70': '60s-70s',
    '70+': '70+'
  },
  [EStep.HAVE_YOU_BEEN_DIAGNOSED_WITH_ANY]: {
    EARLY_MENOPAUSE: 'Early menopause',
    LOW_TESTOSTERONE_LEVEL: 'Low testosterone level',
    NONE: 'None of the above'
  },
  [EStep.FINGER_PRICK_QUESTION]: {
    OPEN_AND_READY: 'Open and ready',
    NERVOUS_BUT_CURIOUS: 'Nervous but curious',
    PREFER_NOT_TO: 'I would prefer not to'
  }
};

export function mapToQuizOptions(step: EStep): TQuizOption[] {
  // @ts-ignore
  const value = mapSlideOptions[step];
  if (!value) {
    return [];
  }

  return Object.values(value).map((title, index) => ({ id: index + 1, title: title as string }));
}

export const excludedForTrackingQuizSteps = [
  EStep.AGE_FACT,
  EStep.HORMONAL_FACT,
  EStep.HARMON_NOT_SUITABLE,
  EStep.FIRST_NAME_GREETING,
  EStep.FEMALE_CONDITIONS_FACT,
  EStep.MALE_CONDITIONS_FACT,
  EStep.HARMON_CAN_HELP_FACT_FEMALE,
  EStep.HARMON_CAN_HELP_FACT_MALE
];
