import { EBloodTest, ETaskDue } from '@/@types/domain';
import type { Patient, PatientCollection, User } from '@/Api/Api';
import { DefineStoreOptions, Store, StoreDefinition } from 'pinia';
import type { TFetchState } from '../core/Domain';

export enum EDoctorTask {
  HAVE_CONSULTATION = 'have-consultation',
  PROVIDE_TREATMENT_PLAN = 'provide-treatment-plan',
  CHECK_BLOOD_TEST = 'check-blood-test'
}

export type TDoctor = User;
export type TPatient = Patient;
export type TAction = any;
export type TConsultation = any;
export type TBloodTest = any;
export type TDoctorTask = any;
export type TDoctorTasks = { [key in ETaskDue]: TDoctorTask[] };

export type TSummaryPayload = {
  summary: string;
  consultationId: number;
};

export type TState = TFetchState & {
  patients: TPatient[];
  patient: TPatient;
  doctor: TDoctor;
  actions: any;
  consultation: TConsultation;
  consultations: TConsultation[];
  bloodTests: TBloodTest[];
  bloodTest: TBloodTest;
  isCallCompleting: boolean;
  tasks: TDoctorTasks;
  pagination?:{
    current_page: number;
    last_page: number;
    total: number;
    per_page: number;
  };
};

export type TGetters = {
  fullName(): string;
  patientInitialBloodTest(): TBloodTest;
  patientInitialConsultation(): TConsultation;
  closestConsultation(): TConsultation;
  isClosestConsultationAvailable(): boolean;
  isInitialBloodTestAvailable(): boolean;
  isPatientCompletedConsultationBefore(): boolean;
  patientUserId(): number;
};

export interface IDoctorService {
  readPatient(id: number): Promise<TPatient>;
  readPatients(search: string, page?:number): Promise<PatientCollection>;

  readActions(search?: string): Promise<any>;

  readConsultation(consultationId: string): Promise<TConsultation>;
  readConsultations(patientId: number): Promise<TConsultation[]>;
  finishConsultation(consultationId: number): Promise<void>;
  leaveConsultationSummary(payload: TSummaryPayload): Promise<any>;

  readBloodTests(patientId: number): Promise<TBloodTest[]>;
  readBloodTest(patientId: number, testId: number): Promise<TBloodTest>;

  readTasks(): Promise<TDoctorTasks>;
  completeTask(taskId: number): Promise<void>;
}

export type IActions = {
  onReadActions(search?: string): Promise<void>;

  onGetPatients(search?: string): Promise<void>;
  onGetPatient(id: number): Promise<void>;
  onSetPatient(patient: TPatient): void;
  onResetPatient(): void;

  onSetDoctor(doctor: TDoctor): void;

  onGetConsultation(consultationId: string): Promise<void>;
  onGetConsultations(patientId: number): Promise<void>;
  resetConsultations(): void;
  resetConsultation(): void;
  onFinishConsultation(consultationId: number): Promise<void>;
  onLeaveConsultationSummary(payload: TSummaryPayload): Promise<void>;

  onGetPatientBloodTests(patientId: number): Promise<void>;
  onGetPatientBloodTest(patientId: number, testId: number): Promise<void>;
  setBloodTest(bloodTest: TBloodTest): void;

  onGetTasks(): Promise<void>;
  onCompleteTask(taskId: number): Promise<void>;

  onGetPatientFullInfo(patientId: number): Promise<void>;
  onResetPatientFullInfo(): void;

  setCallCompleting(isCallCompleting: boolean): void;
};

export const initDoctorState = (): TState => {
  return {
    isLoading: false,
    isError: false,
    isCallCompleting: false,
    patient: null,
    actions: null,
    doctor: null,
    patients: [],
    consultation: null,
    consultations: [],
    bloodTests: [],
    bloodTest: null,
    tasks: null
  };
};

export const bloodTestDictionary: { [test: string]: string } = {
  [EBloodTest.AWAITING_SAMPLE_AT_LAB]: 'Wait for sample at laboratory',
  [EBloodTest.PROCESSING]: 'In process',
  [EBloodTest.RESULTS_AT_HEALTHCARE_PROVIDER]: 'Results at healthcare provider',
  [EBloodTest.RESULTS_READY]: 'Results ready',
  [EBloodTest.RESULTS_RECEIVED]: 'Results received'
};

export const DOCTOR_TASK_DICTIONARY: { [key in EDoctorTask]: string } = {
  [EDoctorTask.HAVE_CONSULTATION]: 'Have consultation',
  [EDoctorTask.PROVIDE_TREATMENT_PLAN]: 'Provide treatment plan',
  [EDoctorTask.CHECK_BLOOD_TEST]: 'Check blood test'
};
