import { getCsrfToken } from './useCookie';
import { useUserStore } from '@/stores/User/UserStore';
import { useCoreStore } from '@/stores/CoreModule/CoreStore';
import { EHttpStatus } from '@/core/http/Domain';
import Unauthorized from '@/core/http/errors/Unauthorized';
import { usePatientStore } from '@/stores/Patient/patient.store';
import { TUser } from '@/stores/User/Domain';
import { useDoctorStore } from '@/stores/Doctor/doctor.store';
import { useAdminStore } from '@/stores/Admin/admin.store';
import { intercomHandler } from '@/core/intercom/intercom.handler';
import { TBootOptions } from '@/core/intercom/intercom.domain';
const baseUrl = process.env.VUE_APP_API_URL;

/**
 * @description Get user data and set it to user store
 * @param useGlobalLoading if need to show global loading, default true
 * @returns user entity
 */

export const getMe = async (useGlobalLoading: boolean = true): Promise<TUser> => {
  const userStore = useUserStore();
  if (userStore.isAuth) {
    console.log('[get me] user is already authenticated');
    return;
  }

  if (useGlobalLoading) {
    const coreStore = useCoreStore();
    coreStore.setIsLoading();
  }

  try {
    let token = getCsrfToken();
    if (!token) {
      await fetch(`${baseUrl}/sanctum/csrf-cookie`, { credentials: 'include' });
    }

    token = getCsrfToken();

    const headers = { 'X-XSRF-TOKEN': token, Accept: 'application/json' };
    const response = await fetch(`${baseUrl}/api/me`, { method: 'GET', credentials: 'include', headers });

    if (response.status === EHttpStatus.UNAUTHORIZED) {
      throw new Unauthorized();
    }

    const res = await response.json();

    const user: TUser = res.data;

    userStore.onSetUser(user);

    if (!userStore.isPatient && intercomHandler.isBooted) {
      intercomHandler.shutdown();
    }

    const { first_name, last_name, email, created_at } = user;
    const bootIntercomPayload: TBootOptions = {
      name: `${first_name} ${last_name}`,
      email,
      created_at: Math.round(new Date(created_at).getTime() / 1000)
    };

    if (userStore.isPatient) {
      const patientStore = usePatientStore();
      patientStore.onSetPatient(user);
      patientStore.setName(userStore.patientName);
      patientStore.setEmail(user.email);
      patientStore.setPatientId(user.patient.id);

      const { is_registration_complete, is_paying_customer } = user?.patient || {};

      if (is_registration_complete && is_paying_customer) {
        if (intercomHandler.isBooted) {
          intercomHandler.shutdown();
        }

        intercomHandler.boot(bootIntercomPayload);
      } else {
        // intercomHandler.bootGuest();
      }
    }

    if (userStore.isDoctor) {
      const doctorStore = useDoctorStore();
      doctorStore.onSetDoctor(user);
      intercomHandler.boot(bootIntercomPayload);
    }

    if (userStore.isAdmin) {
      const adminStore = useAdminStore();
      adminStore.onSetAdmin(userStore.user);
    }

    return user;
  } catch (err) {
    console.log({ err });
    if (!intercomHandler.isBooted) {
      intercomHandler.bootGuest();
    }

    throw err;
  } finally {
    if (useGlobalLoading) {
      const coreStore = useCoreStore();
      coreStore.removeIsLoading();
    }
  }
};
