import { TDoctor } from './../Admin/admin.domain';
import { useNotificationStore } from '@/stores/Notification/NotificationStore';
import { EVerificationIdStatus } from '@/@types/domain';
import { useApi } from '@/composables/useApi';
import { EHttpStatus } from '@/core/http/Domain';
import { consultationMapper } from '@/helpers/mappers/ConsultationMapper';
import { defineStore } from 'pinia';
import type {
  IActions,
  TBloodTest,
  TGetters,
  TOrder,
  TPatient,
  TPatientId,
  TState,
  TUpdateAddress,
  TUser,
  TConsultation
} from './patient.domain';
import { initPatientState } from './patient.domain';
import { patientService } from './patient.service';
import { cloneDeep } from 'lodash';

const api = useApi().getApiInstance();

const usePatientStore = defineStore<'patient', TState, TGetters, IActions>('patient', {
  state: () => initPatientState(),

  getters: {
    idVerificationStatus(): EVerificationIdStatus {
      const id_verification_status = this.patient?.patient?.id_verification_status as EVerificationIdStatus;
      return id_verification_status || (this.idVerification?.status as EVerificationIdStatus) || null;
    },

    initialBloodTest(): TBloodTest {
      if (!this.bloodTests.length) {
        return null;
      }

      return this.bloodTests[0];
    },

    initialConsultation(): TConsultation {
      if (this.patientEntity?.initial_consultation) {
        return this.patientEntity?.initial_consultation;
      }

      if (!this.consultations.length) {
        return null;
      }

      const consultations = cloneDeep(this.consultations);
      consultations.sort((a: any, b: any) => {
        const createdAtA = new Date(a.created_at);
        const createdAtB = new Date(b.created_at);

        return createdAtA.getTime() - createdAtB.getTime();
      });

      return consultations[0];
    },

    patientEntity(): TPatient {
      return this.patient?.patient || null;
    },

    iQuestionnaireCompleted(): boolean {
      const { has_completed_questionnaire } = this.patientEntity || {};
      return !!has_completed_questionnaire;
    },

    isOnboardingDocumentReady(): boolean {
      const isIdApproved = this.idVerificationStatus === EVerificationIdStatus.APPROVED;
      return isIdApproved && this.iQuestionnaireCompleted;
    }
  },

  actions: {
    async onCreateOrder(): Promise<void> {
      await patientService.createOrder();
    },
    onSetOrder(order: TOrder): void {
      this.order = order;
    },
    async onGetOrders(): Promise<void> {
      this.orders = await patientService.readOrders();
    },

    async onGetIdVerification(): Promise<void> {
      const idVerification = await patientService.readIdVerification();
      this.idVerification = idVerification;
      if (!!idVerification) {
        this.patient.patient.id_verification_status = idVerification?.status;
      }
    },

    async onGetBloodTests(): Promise<void> {
      this.bloodTests = await patientService.readBloodTests();
    },

    async onGetBloodTest(id: number): Promise<void> {
      this.bloodTest = await patientService.readBloodTest(id);
    },

    async onGetMetrics(): Promise<void> {
      this.metrics = await patientService.readMetrics();
    },

    async onGetTransactions(): Promise<void> {
      this.transactions = await patientService.readTransactions();
    },

    async onGetPromotions(): Promise<void> {
      this.promotions = await patientService.readPromotions();
    },
    async onGetApplicablePromotion(productType: string): Promise<void> {
      return await patientService.applicablePromotion(productType);
    },

    onSetPatient(patient: TUser): void {
      this.patient = patient;
    },

    async onFetchConsultations(): Promise<void> {
      const consultations = await patientService.readConsultations();
      this.setConsultations(consultationMapper(consultations));
    },

    async onFetchConsultation(id: number): Promise<void> {
      const consultation = await patientService.readConsultation(id);
      this.setConsultation(consultationMapper([consultation])[0]);
    },

    onResetConsultation(): void {
      this.setConsultation(null);
    },

    setViewedQuestionnaire(): void {
      this.patient.patient.viewed_questionnaire = true;
    },

    async readAddress(): Promise<void> {
      try {
        this.isLoading = true;
        this.isError = false;
        const { data, status } = await api.api.showAddress();
        if (status !== EHttpStatus.SUCCESS) {
          return;
        }

        this.address = data.data;
      } catch (err) {
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
    async updateAddress(payload: TUpdateAddress, pageName: string): Promise<EHttpStatus> {
      try {
        this.isLoading = true;
        const { data, status } = await api.api.updateAddress(payload);
        if (status !== EHttpStatus.SUCCESS) {
          return;
        }

        this.address = data.data;
        this.status = status;
        if(pageName != 'OPC') {
          useNotificationStore().addNotification({ type: 'success', title: 'Success', text: 'Address updated.' });
        }
        return status;
      } catch (err) {
        console.log(err);
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },

    setName(name: string): void {
      this.name = name;
    },

    setConsultations(consultations: any[]): void {
      this.consultations = consultations;
    },

    setConsultation(consultation: any | null): void {
      this.consultation = consultation;
    },

    setEmail(email: string): void {
      this.email = email;
    },

    setPatientId(id: TPatientId): void {
      this.id = id;
    },

    async readPatient(patientId: number): Promise<void> {
      console.log({ patientId });
    },

    async onGetDoctorsList(): Promise<void> {
      this.doctors = await patientService.readDoctors();
    },

    onSetDoctor(doctor: TDoctor): void {
      this.doctor = doctor;
    },

    setCallCompleting(isCallCompleting: boolean): void {
      this.isCallCompleting = isCallCompleting;
    }
  }
});

export { usePatientStore };
