import { IApi, useApi } from '@/composables/useApi';
import { axiosInstance } from '@/core/http/axiosInstance';
import { getQueryParams } from '@/helpers/url';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { TPatient } from '../Doctor/doctor.domain';
import type { IAdminService, TAdminTasks, TCreateDoctorPayload, TDoctor } from './admin.domain';
import TryCatch from '@/core/decorators/TryCatch';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';

import { PatientCollection } from '@/Api/Api';

class AdminService implements IAdminService {
  private readonly axios: AxiosInstance;
  private readonly swagger: IApi;

  constructor() {
    this.axios = axiosInstance;
    this.swagger = useApi().getApiInstance();
  }

  @TryCatch()
  public async confirmPatientQuestionnaire(patientId: number): Promise<any> {
    const { data } = await this.axios.post(`api/admin/patient/${patientId}/completed-questionnaires`);
    return data.data;
  }

  @TryCatch()
  public async completeTask(taskId: number): Promise<void> {
    await this.axios.post(`api/admin/completed-tasks/${taskId}`);
  }

  @TryCatch()
  public async readTasks(): Promise<TAdminTasks> {
    const { data } = await this.axios.get(`api/admin/tasks`);
    return data.data;
  }

  @TryCatch()
  public async rejectVerification(patientId: number, comment: string = ''): Promise<any> {
    const payload = {};
    if (comment) {
      // @ts-ignore
      payload.rejection_reason = comment;
    }

    const { data } = await this.axios.post(`api/admin/patient/${patientId}/rejected-id-verifications`, payload);
    return data.data;
  }

  @TryCatch()
  public async deleteVerification(patientId: number, comment: string = ''): Promise<any> {
    const payload = {};
    if (comment) {
      // @ts-ignore
      payload.delete_reason = comment;
    }

    const { data } = await this.axios.post(`api/admin/patient/${patientId}/deleted-id-verifications`, payload);
    return data.data;
  }

  @TryCatch()
  public async readPatientTransactions(patientId: number): Promise<any> {
    const { data } = await this.axios.get(`api/admin/patients/${patientId}/charges`);
    return data.data;
  }

  @TryCatch()
  public async readMetrics(): Promise<any> {
    const { data } = await this.axios.get(`api/admin/metrics`);
    return data.data;
  }

  @TryCatch()
  public async fetchVerifyInfo(patientId: number): Promise<any> {
    const { data } = await this.axios.get(`api/admin/patient/${patientId}/id-verifications`);
    return data;
  }

  @TryCatch()
  public async verifyPatient(patientId: number): Promise<any> {
    const { data } = await this.axios.post(`api/admin/patient/${patientId}/id-verifications`);
    return data.data;
  }

  @TryCatch()
  public async readPatientTreatmentsPlan(id: number): Promise<any> {
    const { data } = await this.axios.post(`api/admin/patients/${id}/treatment-plans`);
    return data.data;
  }

  @TryCatch()
  public async createDoctor(payload: TCreateDoctorPayload): Promise<any> {
    const { status, data } = await this.axios.post(`api/admin/doctors`, payload);
    return { status, data };
  }

  @TryCatch()
  public async readDoctor(id: number): Promise<TDoctor> {
    const { data } = await this.axios.get(`api/admin/doctors/${id}`);
    return data.data;
  }

  @TryCatch()
  public async readDoctors(search?: string): Promise<TDoctor[]> {
    const config: AxiosRequestConfig = {};
    const queryParams: AxiosRequestConfig['params'] = {};

    if (search) {
      queryParams.search = search;
      config.params = new URLSearchParams(queryParams);
    }

    const { data } = await this.axios.get(`api/admin/doctors`, config);
    return data.data;
  }

  @TryCatch()
  public async readPatient(id: number): Promise<any> {
    const { status, data } = await this.axios.get(`api/admin/patients/${id}`);
    return { status, data };
  }

  @TryCatch()
  public async readPatients(search: string = '', page :number = 1): Promise<PatientCollection> {
    const config: AxiosRequestConfig = {};
    const queryParams: AxiosRequestConfig['params'] = { page };
    const urlQueryParams = getQueryParams();

    queryParams.is_paying_customer = true;

    if (search) {
      queryParams.search = search;
    }

    if (has(urlQueryParams, 'only_active')) {
      queryParams.only_active = true;
    }

    if (!isEmpty(queryParams)) {
      config.params = new URLSearchParams(queryParams);
    }

    const { data } = await this.axios.get(`api/admin/patients`, config);
    console.log(data);
    return data;
  }

  @TryCatch()
  public async addPatientApplicableForDiscount(payload: { email: string }): Promise<any> {
    const { status, data } = await this.axios.post(`api/admin/users-applicable-for-discount`, payload);
    return { status, data };
  }

  @TryCatch()
  public async readPatientsApplicableForDiscounts(): Promise<{ id: number, email: string}[]> {
    const { data } = await this.axios.get(`api/admin/users-applicable-for-discount`);
    return data.data;
  }

  @TryCatch()
  public async deletePatientApplicableForDiscounts(id: number): Promise<void> {
    await this.axios.delete(`api/admin/users-applicable-for-discount/`+id);
  }

  @TryCatch()
  public async addPromotion(payload: { title: string }): Promise<any> {
    const { status, data } = await this.axios.post(`api/admin/add-promotion`, payload);
    return { status, data };
  }

  @TryCatch()
  public async readPromotion(): Promise<{ id: number, title: string}[]> {
    const { data } = await this.axios.get(`api/admin/add-promotion`);
    return data.data;
  }

  @TryCatch()
  public async deletePromotion(id: number): Promise<void> {
    await this.axios.delete(`api/admin/add-promotion/`+id);
  }
  
  @TryCatch()
  public async disabledPromotion(id: number, status: number): Promise<void> {
    await this.axios.post(`api/admin/disable-promotion/`+id+`/status/`+status);
  }

  @TryCatch()
  public async deletePatientApplicableForPromotion(id: number): Promise<void> {
    await this.axios.delete(`api/admin/patient-applicable-for-promotion/`+id);
  }

  @TryCatch()
  public async readPatientsApplicableForPromotions(id: number): Promise<any> {
    const { data } = await this.axios.get(`api/admin/patient-applicable-for-promotion/${id}`);
    return data.data;
  }

}

export const adminService = new AdminService();
