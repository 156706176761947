import { TUserStore } from '@/stores/User/Domain';
import { TRouterGuard } from '@/@types/router';

export default function ({ to, from, next }: TRouterGuard, store: TUserStore) {
  const signUpSteps = ['sign-up', 'sign-up-main', 'sign-up-details', 'verify-email'];
  const toName = to.name as string;

  const registerRoutes = [...signUpSteps, 'sign-in', 'forgot-password', 'forgot-password-success', 'password-reset'];

  const { isAuth, isFinishFirstStep, hasVerifiedEmail } = store;
  const { query } = to;

  if (isAuth && registerRoutes.includes(toName)) {
    next({ name: store.userProfileLink, query });
    return;
  }

  if (!isAuth && registerRoutes.includes(toName) && !signUpSteps.includes(toName)) {
    next();
    return;
  }

  if (!isAuth && !registerRoutes.includes(toName)) {
    next({ name: 'sign-up', query });
    return;
  }

  if (toName === 'verify-email' && !isFinishFirstStep) {
    next({ name: 'sign-up', query });
    return;
  }

  if (toName === 'sign-up-details' && !hasVerifiedEmail) {
    next({ name: 'verify-email', query });
    return;
  }

  next();
}
