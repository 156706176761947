import { useNotificationStore } from '@/stores/Notification/NotificationStore';
import { EHttpStatus } from './Domain';

export async function fetchDataAndErrorHandler<T>(baseUrl: string, requestFunction: () => T): Promise<T> {
  try {
    const response = await requestFunction();
    // @ts-ignore
    if (response.ok) {
      return response;
    }

    // @ts-ignore
    const errorResponse = await response.json();
    // @ts-ignore
    const { status } = response;

    const errorMessage = errorResponse?.message || null;
    if (errorMessage) {
      if ([EHttpStatus.NOT_FOUND, EHttpStatus.SERVER_ERROR, EHttpStatus.UNAUTHORIZED].includes(status)) {
        return;
      }

      const noticeStore = useNotificationStore();
      noticeStore.addNotification({
        type: 'error',
        title: status,
        text: errorMessage
      });
    }
    throw { status, errorResponse };
  } catch (err) {
    console.log(err);
  }
}
