import { EHttpStatus } from '@/core/http/Domain';
import { AxiosError } from 'axios';
import { DefineStoreOptions, StateTree, StoreDefinition, _GettersTree } from 'pinia';
import { useNotificationStore } from '../Notification/NotificationStore';
import router from '@/router';

/**
 * Creates a `useStore` function that retrieves the store instance
 *
 * @param id - id of the store (must be unique)
 * @param options - options to define the store
 */
export declare function defineStore<
  Id extends string,
  S extends StateTree = {},
  G extends _GettersTree<S> = {},
  A = {}
>(id: Id, options: Omit<DefineStoreOptions<Id, S, G, A>, 'id'>): StoreDefinition<Id, S, G, A>;

export type TFetchState = {
  isLoading: boolean;
  isError: boolean;
};

export interface IFetchActions {
  setIsLoading(): void;
  removeIsLoading(): void;
  setIsError(): void;
  removeIsError(): void;
}

export const fetchCatcher = <T extends TFetchState>(store: T): T => {
  for (const item of Object.getOwnPropertyNames(store)) {
    // @ts-ignore
    const method = store[item];
    if (typeof method === 'function' && !item.startsWith('$') && !item.startsWith('_')) {
      // @ts-ignore
      store[item] = function () {
        try {
          store.isLoading = true;
          store.isError = false;

          // @ts-ignore
          // eslint-disable-next-line prefer-rest-params
          const result = method(...arguments);
          // Check if method is asynchronous
          if (result && result instanceof Promise) {
            // Return promise
            return result
              .then(() => {
                // @ts-ignore
                store.isLoading = false;
              })
              .catch((error: any) => {
                _handleError(store, error);
              });
          }
          store.isLoading = false;
          return result;
        } catch (error: any) {
          _handleError(store, error);
        }
      };
    }
  }
  return store;
};

function _handleError(ctx: any, error: Error) {
  // @ts-ignore
  ctx.isError = true;
  // @ts-ignore
  ctx.isLoading = false;
  const isAxiosError = error instanceof AxiosError;

  if (isAxiosError) {
    const { status } = error.response || {};
    if ([EHttpStatus.NOT_FOUND].includes(status)) {
      window.location.href = '/not-found';
      return;
    }

    if ([EHttpStatus.SERVER_ERROR, EHttpStatus.UNAUTHORIZED].includes(status)) {
      return;
    }

    useNotificationStore().addNotification({
      type: 'error',
      title: error.message,
      text: error.response?.data?.message
    });
    return;
  }
  throw error;
}

export const baseStore: IFetchActions & TFetchState = {
  isLoading: false,
  isError: false,

  setIsLoading(): void {
    this.isLoading = true;
    this.isError = false;
  },

  removeIsLoading(): void {
    this.isLoading = false;
  },

  setIsError(): void {
    this.isError = true;
    this.isLoading = false;
  },

  removeIsError(): void {
    this.isError = false;
  }
};
