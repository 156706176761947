import { TUserStore } from '@/stores/User/Domain';
import { TRouterGuard } from '@/@types/router';
import { useUser } from '@/composables/useUser';
import { getQueryParams } from '@/helpers/url';

export default function ({ to, from, next }: TRouterGuard, store: TUserStore) {
  if (!store.isAuth && !store.isPatient) {
    return;
  }

  if (store.isPatient) {
    const { is_paying_customer } = store.user.patient;

    if (!is_paying_customer) {
      const allowedRoutes = ['cart', 'cart-result', 'cart-processing'];
      if (allowedRoutes.includes(to.name as string)) {
        next();
        return;
      } else {
        next({ name: 'sign-up' });
        return;
      }
    }
    const queries = getQueryParams();
    const returnUrl = queries['return-redirect-url'];

    if (returnUrl && returnUrl !== to.path) {
      next({ path: returnUrl });
    } else {
      next();
    }
    return;
  }

  const { userProfileLink } = useUser();
  next({ name: userProfileLink.value });
}
