import { useNotificationStore } from '@/stores/Notification/NotificationStore';
import { doctorMapper } from './doctor.mapper';
import { doctorService } from './doctor.service';
import { defineStore } from 'pinia';
import {
  IActions,
  initDoctorState,
  TBloodTest,
  TConsultation,
  TGetters,
  TPatient,
  TState,
  TSummaryPayload
} from './doctor.domain';
import { EConsultationStatus } from '@/@types/domain';

const useDoctorStore = defineStore<'doctor', TState, TGetters, IActions>('doctor', {
  state: () => initDoctorState(),

  getters: {
    patientUserId(): number | null {
      if (!this.patient) {
        return null;
      }

      return this.patient.user_id;
    },
    canStartConsultation(): boolean {
      if (!this.patient) {
        return false;
      }

      return this.patient.completed_consultations_count === 0;
    },

    isPatientCompletedConsultationBefore(): boolean {
      if (!this.patient) {
        return false;
      }

      return this.patient.completed_consultations_count > 0;
    },

    fullName(): string {
      if (!this.doctor) {
        return 'Doctor';
      }

      const { last_name, first_name } = this.doctor;
      return `${first_name} ${last_name}`;
    },

    patientInitialBloodTest(): TBloodTest {
      if (!this.bloodTests.length) {
        return null;
      }

      return this.bloodTests[0];
    },

    isInitialBloodTestAvailable(): boolean {
      if (!this.patientInitialBloodTest) {
        return false;
      }

      return !!(this.patientInitialBloodTest?.results && this.patientInitialBloodTest?.results.length);
    },

    patientInitialConsultation(): TConsultation {
      if (!this.consultations.length) {
        return null;
      }

      return this.consultations[0];
    },

    closestConsultation(): TConsultation {
      if (!this.consultations.length) {
        return null;
      }

      const scheduledConsultations = this.consultations.filter(({ status }) =>
        [EConsultationStatus.SCHEDULED, EConsultationStatus.IN_PROGRESS].includes(status)
      );

      if (!scheduledConsultations.length) {
        return null;
      }

      return scheduledConsultations.sort((a, b) => a.starts_at - b.starts_at)[0] || null;
    },

    isClosestConsultationAvailable(): boolean {
      if (!this.closestConsultation) {
        return false;
      }

      const fifteenMinutes = 15 * 60 * 1000;
      const currentTime = new Date().getTime();
      const consultationStartsAt = new Date(this.closestConsultation?.starts_at).getTime();
      const consultationAvailableTill = consultationStartsAt + 24 * 60 * 60 * 1000;

      return consultationAvailableTill - currentTime > 0 && consultationStartsAt - currentTime <= fifteenMinutes;
    }
  },

  actions: {
    async onCompleteTask(taskId: number): Promise<void> {
      await doctorService.completeTask(taskId);
      await this.onGetTasks();
    },

    async onGetTasks(): Promise<void> {
      this.tasks = await doctorService.readTasks();
    },

    async onGetConsultation(consultationId: string): Promise<void> {
      const rawConsultation = await doctorService.readConsultation(consultationId);
      this.consultation = doctorMapper.consultationsMapper([rawConsultation])[0];
    },

    async onLeaveConsultationSummary(payload: TSummaryPayload): Promise<void> {
      await doctorService.leaveConsultationSummary(payload);
      useNotificationStore().addNotification({ type: 'success', text: 'Summary sent to the patient' });
    },

    async onFinishConsultation(consultationId: number): Promise<void> {
      this.isLoading = true;
      await doctorService.finishConsultation(consultationId);
      this.isLoading = false;
    },

    onResetPatientFullInfo(): void {
      this.patient = null;
      this.bloodTests = [];
      this.consultations = [];
    },

    async onGetPatientFullInfo(patientId: number): Promise<void> {
      await Promise.all([
        this.onGetPatient(patientId),
        this.onGetPatientBloodTests(patientId),
        this.onGetConsultations(patientId)
      ]);
    },

    async onGetPatientBloodTests(patientId: number): Promise<void> {
      this.bloodTests = await doctorService.readBloodTests(patientId);
    },

    async onGetPatientBloodTest(patientId: number, testId: number): Promise<void> {
      this.bloodTest = await doctorService.readBloodTest(patientId, testId);
    },

    setBloodTest(bloodTest: TBloodTest): void {
      this.bloodTest = bloodTest;
      if (!this.bloodTests.length) {
        return;
      }

      const index = this.bloodTests.findIndex((test) => test.id === bloodTest.id);

      if (index === -1) {
        return;
      }

      this.bloodTests.splice(index, 1, bloodTest);
    },

    async onGetConsultations(patientId: number): Promise<void> {
      const rawConsultations = await doctorService.readConsultations(patientId);
      this.consultations = doctorMapper.consultationsMapper(rawConsultations);
    },

    resetConsultations(): void {
      this.consultations = [];
    },

    resetConsultation(): void {
      this.consultation = null;
    },

    async onReadActions(search?: string): Promise<void> {
      this.actions = await doctorService.readActions(search);
    },

    onSetPatient(patient: TPatient): void {
      this.patient = patient;
    },

    onResetPatient(): void {
      this.patient = null;
    },

    onSetDoctor(doctor: any): void {
      this.doctor = doctor;
    },

    async onGetPatient(id: number): Promise<void> {
      this.patient = await doctorService.readPatient(id);
    },

    async onGetPatients(search: string = '', page: number = 1 ): Promise<void> {
      const patients = await doctorService.readPatients(search,page);
      patients.data.sort(
        (a: TPatient, b: TPatient) => new Date(b.registered_at).getTime() - new Date(a.registered_at).getTime()
      );
      this.pagination=patients.pagination
      this.patients = patients.data;
    },
    setCallCompleting(isCallCompleting: boolean): void {
      this.isCallCompleting = isCallCompleting;
    }
  }
});

export { useDoctorStore };
