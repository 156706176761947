/**
 * get query params from url
 * @return {Object}
 */
const getQueryParams = (): any => {
  let match;
  const pl = /\+/g; // Regex for replacing addition symbol with a space
  const search = /([^&=]+)=?([^&]*)/g;
  const decode = function (s: any) {
    return decodeURIComponent(s.replace(pl, ' '));
  };
  const query = window.location.search.substring(1);

  const urlParams: any = {};
  while ((match = search.exec(query))) {
    urlParams[decode(match[1])] = decode(match[2]);
  }

  return urlParams;
};

/**
 * delete url params without reloading the page
 * @param {Array} removeParams
 */
const removeUrlParameters = (removeParams: Array<any>) => {
  const deleteRegex = new RegExp(removeParams.join('=|') + '=');

  const params = location.search.slice(1).split('&');
  const search = [];
  for (let i = 0; i < params.length; i++) {
    if (deleteRegex.test(params[i]) === false) {
      search.push(params[i]);
    }
  }

  window.history.replaceState(
    {},
    document.title,
    location.pathname + (search.length ? `?${search.join('&')}` : '') + location.hash
  );
};

/**
 * update url params without reloading the page
 * @param {String} param
 * @param {String} value
 */
const updateUrlParameter = (param: string, value: string): void => {
  if (typeof value === 'object') {
    value = JSON.stringify(value);
  }

  const baseUrl = [location.protocol, '//', location.host, location.pathname].join('');
  const urlQueryString = document.location.search;
  const newParam = `${param}=${value}`;
  let params = `?${newParam}`;

  // If the "search" string exists, then build params from it
  if (urlQueryString) {
    const keyRegex = new RegExp(`([?&])${param}[^&]*`);
    // If param exists already, update it
    if (urlQueryString.match(keyRegex) !== null) {
      params = urlQueryString.replace(keyRegex, `$1${newParam}`);
    } else {
      // Otherwise, add it to end of query string
      params = `${urlQueryString}&${newParam}`;
    }
  }
  window.history.replaceState({}, document.title, baseUrl + params);
};

function serializeQuery(params: any, prefix: string): any {
  const query = Object.keys(params).map((key) => {
    const value = params[key];

    if (params.constructor === Array) {
      key = `${prefix}[]`;
    } else if (params.constructor === Object) {
      key = prefix ? `${prefix}[${key}]` : key;
    }

    if (typeof value === 'object') {
      return serializeQuery(value, key);
    } else {
      return `${key}=${encodeURIComponent(value)}`;
    }
  });

  // eslint-disable-next-line prefer-spread
  return [].concat.apply([], query).join('&');
}

const marketingUrl = (uri: string): string => {
  const baseUrl = process.env.VUE_APP_MARKETING_URL;

  return `${baseUrl}${uri || ''}`;
};

const marketingHelpUrl = (uri: string): string => {
  const baseUrl = process.env.VUE_APP_MARKETING_HELP_URL;

  return `${baseUrl}${uri || ''}`;
};

export { updateUrlParameter, getQueryParams, removeUrlParameters, serializeQuery, marketingUrl,marketingHelpUrl };
