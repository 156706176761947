import type { TComponentClass } from './@types';
import { EButtonAppearance, ETagAppearance } from './@types';

export const BUTTON_CLASSES: TComponentClass = {
  [EButtonAppearance.PRIMARY]:
    'bg-plantation w-full rounded text-desktop-p leading-none text-white font-medium focus:ring-lime-700 hover:bg-lime-900',
  [EButtonAppearance.SECONDARY]:
    'inline-flex justify-center items-center w-full text-plantation text-[18px] leading-none font-medium bg-transparent hover:bg-white border-[2px] border-plantation rounded',
  [EButtonAppearance.TRANSPARENT]: 'font-medium underline hover:no-underline text-plantation',
  [EButtonAppearance.INVERTED]:
    'bg-bianca text-plantation w-full rounded text-desktop-p leading-none font-medium focus:ring-moon focus:ring-offset-transparent hover:bg-moon',
  [EButtonAppearance.CANCEL]:
    'bg-red-500 text-white rounded text-desktop-p leading-none font-medium focus:ring-moon focus:ring-offset-transparent hover:bg-red-700',
  [EButtonAppearance.ALERT]:
    'px-4 py-2 rounded-lg bg-red-200 ring ring-red-400 text-red-900 font-bold tracking-wider uppercase hover:bg-red-300 hover:ring-red-600 transition focus:ring-offset-2 md:mt-8'
};

export const TAG_CLASSES: TComponentClass = {
  [ETagAppearance.DEFAULT]: 'bg-plantation text-white',
  [ETagAppearance.DRAFT]: 'bg-iron text-white',
  [ETagAppearance.MINT]: 'bg-mint text-plantation',
  [ETagAppearance.LIME]: 'bg-lemon text-plantation',
  [ETagAppearance.SCHEDULED]: 'bg-mint text-plantation',
  [ETagAppearance.PENDING]: 'bg-mint text-plantation',
  [ETagAppearance.CONFIRMED]: 'bg-plantation text-white',
  [ETagAppearance.APPROVED]: 'bg-plantation text-white',
  [ETagAppearance.COMPLETED]: 'bg-plantation text-white',
  [ETagAppearance.CLOUD]: 'bg-cloud text-white',
  [ETagAppearance.IRON]: 'bg-iron text-white',
  [ETagAppearance.TASMAN]: 'bg-tasman text-plantation',
  [ETagAppearance.ACCEPTED]: 'bg-mint text-plantation',
  [ETagAppearance.IN_PROGRESS]: 'bg-plantation text-white',
  [ETagAppearance.REJECTED]: 'bg-red-100 text-red'
};

export const LIGHT_BUTTON_CLASSES: TComponentClass = {
  [EButtonAppearance.PRIMARY]: 'bg-mint text-graphite'
};

export const NOTICE_PAGE_TYPE_ALIVE = 6000; // 6 seconds

export const noticeComponentClasses = {
  success: 'border-plantation bg-green-100',
  error: 'border-rose-600 bg-rose-100 text-rose-900',
  info: 'border-plantation bg-spring-wood',
  light: 'border-spring-wood-600 bg-spring-wood-100'
};

export const noticeIconClasses = {
  success: 'text-plantation',
  error: 'text-rose-600',
  info: 'text-darkGray'
};
