import { TUserStore } from '@/stores/User/Domain';
import { TRouterGuard } from '@/@types/router';

export default function ({ to, from, next }: TRouterGuard, store: TUserStore) {
  const toName = to.name as string;

  const isLoggedIn = !!store.user;

  if (isLoggedIn) {
    next({ name: store.userProfileLink });
    return;
  }

  next();
}
