export enum EHttpStatus {
  SUCCESS = 200,
  CREATED = 201,
  NO_CONTENT = 204,
  UNAUTHORIZED = 401,
  PERMISSION_DENIED = 403,
  NOT_FOUND = 404,
  EXPIRED = 419,
  UNPROCESSABLE_ENTITY = 422,
  SERVER_ERROR = 500
}
