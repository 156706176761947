import { TFetchState, IFetchActions } from '../core/Domain';

export type TState = TFetchState & {
  isNavbarShown: boolean;
  isLayoutLoading: boolean;
};

export interface IActions extends IFetchActions {
  setNavbarVisibility(value: boolean): void;
  setLayoutLoading(value: boolean): void;
}

export const initCoreState = (): TState => {
  return {
    isLoading: false,
    isError: false,
    isNavbarShown: true,
    isLayoutLoading: false
  };
};
