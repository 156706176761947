import { axiosInstance } from '@/core/http/axiosInstance';
import { AxiosInstance } from 'axios';
import type { IQuizService, TQuiz, TQuizPayload } from './quiz.domain';
import TryCatch from '@/core/decorators/TryCatch';

class QuizService implements IQuizService {
  private readonly axios: AxiosInstance;

  constructor() {
    this.axios = axiosInstance;
  }

  // @TryCatch()
  public async shareEmail(id: number, email: string, message?: string): Promise<void> {
    const payload: Record<string, string> = { email };
    payload.message = message || 'message';

    await this.axios.post(`api/not-eligible-quizzes/${id}`, payload);
  }

  @TryCatch()
  public async read(id: number): Promise<TQuiz> {
    const { data } = await this.axios.get(`api/quizzes/${id}`);
    return data.data;
  }

  @TryCatch()
  public async create(): Promise<TQuiz> {
    const { data } = await this.axios.post('api/quizzes');
    return data.data;
  }

  @TryCatch()
  public async update(id: number, payload: TQuizPayload): Promise<TQuiz> {
    const { data } = await this.axios.put(`api/quizzes/${id}`, payload);
    return data.data;
  }

  // @TryCatch()
  public async finish(id: number): Promise<void> {
    try {
      await this.axios.post(`api/completed-quizzes/${id}`);
    } catch(err) {
      console.log({ err });
    }
  }
}

export const quizService = new QuizService();
