import { IApi, useApi } from '@/composables/useApi';
import { axiosInstance } from '@/core/http/axiosInstance';
import TryCatch from '@/core/decorators/TryCatch';
import type { TOrder } from '@/core/billing/stripe/Domain';
import type { AxiosInstance } from 'axios';
import type { IPatientService, TBloodTest, TIdVerification } from './patient.domain';
import { TDoctor } from '@/@types/domain';
import DecorateAllMethodsWith from '@/core/utils/decoratorToClass';

@DecorateAllMethodsWith(TryCatch())
class PatientService implements IPatientService {
  private readonly axios: AxiosInstance;
  private readonly swagger: IApi;

  constructor() {
    this.axios = axiosInstance;
    this.swagger = useApi().getApiInstance();
  }

  public async readDoctors(): Promise<TDoctor[]> {
    const { data } = await this.axios.get(`api/patient/doctors`);
    return data.data;
  }

  public async createOrder(): Promise<TOrder> {
    const { data } = await this.axios.post(`api/patient/orders`);
    return data.data;
  }

  public async readOrders(): Promise<TOrder[]> {
    const { data } = await this.axios.get(`api/patient/orders`);
    return data.data;
  }

  public async readIdVerification(): Promise<TIdVerification> {
    const { data } = await this.swagger.api.showIdVerification();
    return data.data;
  }

  public async readBloodTests(): Promise<TBloodTest[]> {
    const { data } = await this.axios.get(`api/patient/blood-tests`);
    return data.data;
  }

  public async readBloodTest(id: number): Promise<TBloodTest> {
    const { data } = await this.axios.get(`api/patient/blood-tests/${id}`);
    return data.data;
  }

  public async readMetrics(): Promise<any> {
    const { data } = await this.axios.get(`api/patient/metrics`);
    return data.data;
  }

  public async readTransactions(): Promise<any> {
    const { data } = await this.axios.get(`api/patient/charges`);
    return data.data;
  }

  public async readPromotions(): Promise<any> {
    const { data } = await this.axios.get(`api/patient/promotions`);
    return data.data;
  }

  public async applicablePromotion(productType: string): Promise<any> {
    const { data } = await this.axios.get(`api/patient/applicable-promotion/product/${productType}`);
    return data.data;
  }

  public async readConsultations(): Promise<any> {
    const { data } = await this.axios.get(`api/patient/consultations`);
    return data.data;
  }

  public async readConsultation(id: number): Promise<any> {
    const { data } = await this.axios.get(`api/patient/consultations/${id}`);
    return data.data;
  }

  public async getGetStartedPackagePrice(): Promise<any> {
    const { data } = await this.axios.get(`api/get-started-package-price`);
    return data;
  }
}

export const patientService = new PatientService();
