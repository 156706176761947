import { Api } from '@/Api/Api';
import customFetch from '@/core/http/customFetch';

export type TApiConfig = {
  baseUrl: string;
  customFetch?: typeof fetch;
  baseApiParams?: any;
};

export type IApi = Api<unknown>;
let api: IApi;
export const useApi = () => {
  const BASE_URL = process.env.VUE_APP_API_URL;
  const apiConfig: TApiConfig = {
    baseUrl: BASE_URL,
    customFetch: customFetch(BASE_URL),
    baseApiParams: { credentials: 'include' }
  };

  const installApi = (config: TApiConfig): void => {
    api = new Api(config || apiConfig);
  };

  const getApiInstance = (): IApi => {
    installApi(apiConfig);
    if (!api) {
      api = new Api(apiConfig);
    }
    return api;
  };
  return { getApiInstance, installApi, api, BASE_URL };
};
