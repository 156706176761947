import { useCoreStore } from '@/stores/CoreModule/CoreStore';
import storage from '@/core/storage/BrowserStorage';
import { fetchCatcher } from '@/stores/core/Domain';
import { useUserStore } from '@/stores/User/UserStore';
import { computed } from 'vue';
import { getActivePinia } from 'pinia';
import { useRouter } from 'vue-router';
import { intercomHandler } from '@/core/intercom/intercom.handler';

export const useUser = () => {
  const userStore = fetchCatcher(useUserStore());

  const user = computed(() => userStore.user);

  const patient = computed(() => userStore.user?.patient);

  const isRegistered = computed(() => userStore.user.patient?.is_registered);
  const isFinishFirstStep = computed(() => {
    if (!userStore.isPatient) {
      return false;
    }

    const { is_registered, is_registration_complete } = userStore.user?.patient;
    return is_registered && !is_registration_complete;
  });

  const signUpStep = computed(() => userStore.signUpStep);

  const userProfileLink = computed(() => {
    const isAdmin = userStore.isAdmin;
    const isDoctor = userStore.isDoctor;
    const isPatient = userStore.isPatient;

    if (isDoctor && isAdmin) {
      return 'doctor-dashboard';
    }

    if (isAdmin) {
      return 'admin';
    } else if (isDoctor) {
      return 'doctor-dashboard';
    } else if (isPatient) {
      return getUserProfileLink();
    } else {
      return 'sign-in';
    }
  });

  const getUserProfileLink = () => {
    const { user } = userStore;
    const hasVerifiedEmail = user?.has_verified_email;
    const isRegistrationComplete = user?.patient?.is_registration_complete;
    const isPayingCustomer = user?.patient?.is_paying_customer;
    const isFinishedRegisteredSteps = hasVerifiedEmail && isRegistrationComplete;

    if (!isRegistrationComplete) {
      return 'sign-up';
    }

    if (isFinishedRegisteredSteps && !isPayingCustomer) {
      return 'sign-up';
    }

    return 'patient-dashboard';
  };

  const onSignOut = async () => {
    const coreStore = useCoreStore();
    // @ts-ignore
    getActivePinia()._s.forEach((store) => {
      store.$reset();
    });

    storage.clear();
    intercomHandler.shutdown();
    coreStore.setIsLoading();

    try {
      await userStore.onSignOut();
    } catch (error) {
    } finally {
      coreStore.removeIsLoading();
    }
  };

  const checkIfPayingCustomerAndRedirect = () => {
    if (!userStore.isPatient) {
      return;
    }

    const { is_paying_customer } = user.value?.patient;
    if (!is_paying_customer) {
      return;
    }

    const router = useRouter();
    router.replace({ name: 'patient-dashboard' });
  };

  return {
    isRegistered,
    isFinishFirstStep,
    signUpStep,
    user,
    userProfileLink,
    onSignOut,
    patient,
    checkIfPayingCustomerAndRedirect
  };
};
