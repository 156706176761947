import { EVerificationIdStatus, TDoctor } from '@/@types/domain';
import type { Address, DocumentVerification, Patient, UpdateAddressRequestSchema, User } from '@/Api/Api';
import { EHttpStatus } from '@/core/http/Domain';
import { TFetchState } from '../core/Domain';

export enum EBloodTest {
  AWAITING_SAMPLE_AT_LAB = 'awaiting_sample_at_lab',
  PROCESSING = 'processing_sample',
  RESULTS_READY = 'results_ready',
  RESULTS_RECEIVED = 'results_received',
  RESULTS_AT_HEALTHCARE_PROVIDER = 'results_at_healthcare_provider'
}

export type TPatientId = number | string;

export type TAdress = Address;
export type TConsultation = any;
export type TPatient = Patient;
export type TUser = User;
export type TUpdateAddress = UpdateAddressRequestSchema;
export type TIdVerification = DocumentVerification;
export type TTransaction = any;
export type TPatientMetrics = any;
export type TBloodTest = any;
export type TOrder = any;
export type TPromotion = any;

export type TState = TFetchState & {
  id: TPatientId;
  address: TAdress;
  name: string;
  email: string;
  consultations: any[];
  consultation: any;
  patient: TUser;
  transactions: TTransaction[];
  metrics: TPatientMetrics;
  status: EHttpStatus;
  bloodTests: TBloodTest[];
  bloodTest: TBloodTest;
  idVerification: TIdVerification;
  orders: TOrder[];
  order: TOrder;
  doctors: TDoctor[];
  doctor: TDoctor;
  isCallCompleting: boolean;
  promotions: TPromotion[];
};

export type TGetters = {
  idVerificationStatus(): EVerificationIdStatus;
  initialBloodTest(): TBloodTest;
  isOnboardingDocumentReady(): boolean;
  patientEntity(): TPatient;
  initialConsultation(): TConsultation;
  iQuestionnaireCompleted(): boolean;
};

export interface IPatientService {
  readConsultations(): Promise<any>;
  readConsultation(id: number): Promise<any>;

  readTransactions(): Promise<any>;
  readMetrics(): Promise<any>;

  readBloodTests(): Promise<TBloodTest[]>;
  readBloodTest(id: number): Promise<TBloodTest>;

  readIdVerification(): Promise<TIdVerification>;

  readOrders(): Promise<TOrder[]>;
  createOrder(): Promise<TOrder>;

  readDoctors(): Promise<TDoctor[]>;

  readPromotions(): Promise<any>;
}

export interface IActions {
  onGetMetrics(): Promise<void>;

  onGetTransactions(): Promise<void>;

  onSetPatient(patient: TUser): void;
  setName(name: string): void;
  setEmail(email: string): void;
  setPatientId(id: TPatientId): void;
  readPatient(patientId: number): Promise<void>;

  onFetchConsultations(): Promise<void>;
  onFetchConsultation(id: number): Promise<void>;
  onResetConsultation(): void;
  setConsultations(consultations: any[]): void;
  setConsultation(consultations: any): void;

  readAddress(): Promise<void>;
  updateAddress(payload: TUpdateAddress, pageName: string): Promise<EHttpStatus>;

  onGetBloodTests(): Promise<void>;
  onGetBloodTest(id: number): Promise<void>;

  onGetIdVerification(): Promise<void>;

  onGetOrders(): Promise<void>;
  onCreateOrder(): Promise<void>;
  onSetOrder(order: TOrder): void;

  onGetDoctorsList(): Promise<void>;
  onSetDoctor(doctor: TDoctor): void;
  setViewedQuestionnaire(): void;
  setCallCompleting(isCallCompleting: boolean): void;

  onGetPromotions(): Promise<void>;
  onGetApplicablePromotion(productType: string): Promise<void>;
}

export const initPatientState = (): TState => {
  return {
    isLoading: false,
    isError: false,
    address: {} as TAdress,
    name: '',
    email: '',
    id: null,
    consultations: [],
    consultation: null,
    patient: null,
    metrics: null,
    transactions: [],
    status: null,
    bloodTests: [],
    bloodTest: null,
    idVerification: null,
    orders: [],
    order: null,
    doctors: [],
    doctor: null,
    isCallCompleting: false,
    promotions: [],
  };
};

export const bloodTestDictionary: { [test: string]: string } = {
  [EBloodTest.AWAITING_SAMPLE_AT_LAB]: 'Waiting for the sample at laboratory',
  [EBloodTest.PROCESSING]: 'Blood Test is being analysed',
  [EBloodTest.RESULTS_AT_HEALTHCARE_PROVIDER]: 'Test Results are ready',
  [EBloodTest.RESULTS_READY]: 'Test Results are ready',
  [EBloodTest.RESULTS_RECEIVED]: 'Test Results are ready'
};
