import { isSafari } from '@/core/utils/is-mobile';
import { format, fromUnixTime } from 'date-fns';
import moment from 'moment';

const formatDate = (timestamp: number): string => {
  if (!timestamp) {
    return '—';
  }

  return format(fromUnixTime(timestamp), 'dd.mm.yyyy');
};

export const convertDate = (dateString: string): string => {
  if (!dateString) {
    return '—';
  }

  const isSafariBrowser = isSafari();
  let date;
  if (isSafariBrowser) {
    date = moment(dateString).toDate();
  } else {
    date = new Date(dateString);
  }

  const rawDays = date.getDate();
  const days = rawDays < 10 ? `0${rawDays}` : rawDays;
  return days + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
};

export const convertHoursAndMinutes = (dateString: string): string => {
  if (!dateString) {
    return '—';
  }

  const isSafariBrowser = isSafari();
  let date;
  if (isSafariBrowser) {
    date = moment(dateString).toDate();
  } else {
    date = new Date(dateString);
  }

  const mins = date.getMinutes();
  const hoursAndMinutes = `${date.getHours()}:${mins < 9 ? `0${mins}` : mins}`;
  return `${hoursAndMinutes}`;
};

export const convertDateAndTime = (dateString: string): string => {
  if (!dateString) {
    return '—';
  }

  const date = convertDate(dateString);
  const time = convertHoursAndMinutes(dateString);

  return `${date} ${time}`;
};

export const getDateDiff = (start: string, end: string): number => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const diff = (startDate.getTime() - endDate.getTime()) / 1000 / 60;
  return Math.abs(Math.round(diff));
};

export { formatDate };

// function to get local time
export const getLocalTime = (date: Date): string => {
  const localTime = date.toLocaleTimeString();
  return localTime;
};

const getDate = () => {
  const isSafariBrowser = isSafari();
  return function (date?: string) {
    if (isSafariBrowser) {
      return date ? moment(date).toDate() : moment().toDate();
    }
    return date ? new Date(date) : new Date();
  };
};

export const getDateByBrowser = getDate();

export const getLondonTimeNow = () => {
  const dateNow = getDateByBrowser();
  const londonTime = dateNow.toLocaleString('en-GB', { timeZone: 'Europe/London' });

  const [date, time] = londonTime.split(',');
  const [month, day, year] = date.split('/');

  const londonDate = `${year}-${day}-${month}`;
  return `${londonDate}${time}`;
};
