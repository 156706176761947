import {
  IService,
  TCreatePayload,
  TResetPassword,
  TRestorePassword,
  TSignInForm,
  TUpdatePayload,
  TUser
} from './Domain';
import { IApi, useApi } from '@/composables/useApi';
import { EHttpStatus } from '@/core/http/Domain';
import { AxiosInstance } from 'axios';
import { axiosInstance } from '@/core/http/axiosInstance';
import DecorateAllMethodsWith from '@/core/utils/decoratorToClass';
import TryCatch from '@/core/decorators/TryCatch';

@DecorateAllMethodsWith(TryCatch())
export default class Service implements IService {
  private readonly api: IApi;
  private readonly axios: AxiosInstance;

  constructor() {
    this.api = useApi().getApiInstance();
    this.axios = axiosInstance;
  }

  public async resendVerificationEmail(): Promise<void> {
    await this.axios.post('email/verification-notification');
  }

  public async forgotPassword(payload: TResetPassword): Promise<any> {
    return this.axios.post('/forgot-password', payload);
  }

  public async restorePassword(payload: TRestorePassword): Promise<any> {
    try {
      const response = await this.axios.post('/reset-password', payload);
      return response;
    } catch (error) {
      return error;
    }
  }

  public async read(): Promise<TUser> {
    const response = await this.api.api.me();
    if (response.status === EHttpStatus.SUCCESS) {
      return response.data.data;
    }

    return null;
  }

  public async create(payload: TCreatePayload): Promise<TUser> {
    const response = await this.api.register.register(payload);
    if (response.status !== EHttpStatus.CREATED) {
      return null;
    }

    return response.data.data;
  }

  public async update(payload: TUpdatePayload): Promise<TUser> {
    const response = await this.api.register.updateRegisterDetails(payload);
    if (response.status !== EHttpStatus.SUCCESS) {
      return null;
    }

    return response.data.data;
  }

  public async delete(): Promise<void> {
    await this.axios.delete('api/me');
  }

  public async signIn(payload: TSignInForm): Promise<EHttpStatus> {
    const response = await this.api.login.login(payload);
    return response.status;
  }

  public async signOut(): Promise<EHttpStatus> {
    const response = await this.api.logout.logout();
    return response.status;
  }

  public async startImpersonateSession(id: number): Promise<void> {
    await this.axios.post(`impersonated-sessions/${id}`);
  }

  public async finishImpersonateSession(): Promise<void> {
    await this.axios.delete('impersonated-sessions');
  }
}
