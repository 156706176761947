import { getCsrfToken } from '@/composables/useCookie';
import { useNotificationStore } from '@/stores/Notification/NotificationStore';
import has from 'lodash/has';
import { fetchDataAndErrorHandler } from './fetchDataAndErrorHandler';
export default function (baseUrl: string) {
  return async function (input: RequestInfo, init?: RequestInit): Promise<Response> {
    try {
      let token = getCsrfToken();
      if (!token) {
        await fetch(`${baseUrl}/sanctum/csrf-cookie`, { credentials: 'include' });
      }

      token = getCsrfToken();

      const { headers } = init;
      init.headers = { ...headers, ...{ 'X-XSRF-TOKEN': token, Accept: 'application/json' } };
      return await fetchDataAndErrorHandler(baseUrl, async () => await fetch(input, init));
    } catch (err) {
      console.log('customFetch', err);

      if (has(err, 'message')) {
        const noticeStore = useNotificationStore();
        // @ts-ignore
        noticeStore.addNotification({ type: 'error', title: 'Error', text: err.message });
      }
    }
  };
}
