import type { TNotification } from '@/@types/components';
import { initNotificationState, TGetters } from './Domain';
import type { TState, IActions } from './Domain';

import { v4 as uuidv4 } from 'uuid';
import { defineStore } from 'pinia';

const useNotificationStore = defineStore<'notification', TState, TGetters, IActions>('notification', {
  state: () => initNotificationState(),

  getters: {
    globalNotices(): TNotification[] {
      return this.notifications;
    }
  },

  actions: {
    addNotification(payload: TNotification): void {
      payload.useDismiss = true;
      payload.id = uuidv4();
      this.notifications.push(payload);
    },

    removeNotification(payload: TNotification): void {
      const index = this.notifications.indexOf(payload);
      this.notifications.splice(index, 1);
    }
  }
});

export { useNotificationStore };
