import { IFetchActions, TFetchState } from '../core/Domain';
import { UpdateRegisteredUserRequestSchema, User, RegisterUserRequestSchema, LoginUserRequestSchema } from '@/Api/Api';
import { EHttpStatus } from '@/core/http/Domain';
import { Store } from 'pinia';

export enum ESignUpStep {
  SIGN_UP,
  SIGN_UP_VERIFY_EMAIL,
  SIGN_UP_DETAILS
}

export type TSignInForm = LoginUserRequestSchema;
export type TUpdatePayload = UpdateRegisteredUserRequestSchema;
export type TCreatePayload = RegisterUserRequestSchema & { thrivaTerms: boolean };
export type TUser = User;

export type TResetPassword = {
  email: string;
};
export type TRestorePassword = {
  token: string;
  email: string;
  password: string;
  password_confirmation: string;
};

export type TStatus = {
  status: string;
};

export type TState = TFetchState & {
  signUpStep: ESignUpStep;
  user: TUser;
  status: EHttpStatus;
};

export interface IService {
  read(): Promise<TUser>;
  create(payload: TCreatePayload): Promise<TUser>;
  update(payload: TUpdatePayload): Promise<TUser | void>;
  delete(): Promise<any>;

  resendVerificationEmail(): Promise<any>;

  signIn(payload: TSignInForm): Promise<EHttpStatus>;
  signOut(): Promise<EHttpStatus>;
  forgotPassword(payload: TResetPassword): Promise<Response>;
  restorePassword(payload: TRestorePassword): Promise<Response>;

  startImpersonateSession(id: number): Promise<void>;
  finishImpersonateSession(): Promise<void>;
}

export type TGetters = {
  isFinishFirstStep(): boolean;
  isTwoStepRegistrationFinish(): boolean;
  userProfileLink(): string;
  isAuth(): boolean;
  isPatient(): boolean;
  isDoctor(): boolean;
  isAdmin(): boolean;
  patientName(): string;
  patient(): User['patient'];
  isImpersonateModeOn(): boolean;
  hasVerifiedEmail(): boolean;
  currentUser(): any;
  isDoctorAndAdmin(): boolean;
  applicableForDiscount(): boolean;
};

export interface IActions extends IFetchActions {
  onSetSignUpStep(step: number): void;

  onGetUser(): Promise<void>;
  onUpdateUser(form: TUpdatePayload): Promise<void>;
  onCreateUser(form: TCreatePayload): Promise<TUser>;
  onDelete(): Promise<void>;

  onResendVerificationEmail(): Promise<void>;

  onSetUser(user: TUser): void;
  onSignIn(payload: TSignInForm): Promise<void>;
  onSignOut(): Promise<void>;
  onForgotPassword(payload: TResetPassword): Promise<EHttpStatus>;
  onResetPassword(payload: TResetPassword): Promise<EHttpStatus>;

  startImpersonateSession(id: number): Promise<void>;
  finishImpersonateSession(): Promise<void>;
}

export const initUserState = (): TState => {
  return {
    isLoading: false,
    isError: false,
    user: null,
    signUpStep: ESignUpStep.SIGN_UP,
    status: null
  };
};

export type TUserStore = Store<'user', TState, TGetters, IActions>;

export const signUpRoutes: { [key in ESignUpStep]: string } = {
  [ESignUpStep.SIGN_UP]: 'sign-up-main',
  [ESignUpStep.SIGN_UP_VERIFY_EMAIL]: 'verify-email',
  [ESignUpStep.SIGN_UP_DETAILS]: 'sign-up-details'
};
