import { defineStore } from 'pinia';
import type { IActions, TState } from './Domain';
import { initCoreState } from './Domain';

export const useCoreStore = defineStore<'core', TState, {}, IActions>('core', {
  state: () => initCoreState(),

  actions: {
    setNavbarVisibility(value: boolean): void {
      this.isNavbarShown = value;
    },

    setLayoutLoading(value: boolean): void {
      this.isLayoutLoading = value;
    },

    setIsLoading(): void {
      this.isLoading = true;
      this.isError = false;
    },

    removeIsLoading(): void {
      this.isLoading = false;
    },

    setIsError(): void {
      this.isError = true;
      this.isLoading = false;
    },

    removeIsError(): void {
      this.isError = false;
    }
  }
});
