export const getCookie = (name: string): string => {
  const matches = document.cookie.match(
    new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const getCookiesStartWith = (name: string): string[] => {
  const cookies = document.cookie.split(';');
  const cookiesStartWith = cookies.filter((cookie) => cookie.trim().startsWith(name));
  return cookiesStartWith.map((cookie) => cookie.trim().split('=')[1]);
};

export const removeCookiesStartWith = (name: string): void => {
  const cookies = document.cookie.split(';');
  const cookiesStartWith = cookies.filter((cookie) => cookie.trim().startsWith(name));
  cookiesStartWith.forEach((cookie) => {
    const cookieName = cookie.trim().split('=')[0];
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  });
};

export const getCsrfToken = (): string | undefined => getCookie('XSRF-TOKEN');
export const getSessionToken = (): string | undefined => getCookie('harmon-session');
