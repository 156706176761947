import { removeCookiesStartWith } from './../../composables/useCookie';
import type { TBootOptions, TBootPayload, IIntercom, TUpdateOptions } from './intercom.domain';
import { EMethod } from './intercom.domain';

class IntercomHandler implements IIntercom {
  private appId: string = process.env.VUE_APP_INTERCOM_APP_ID;
  private apiUrl: string = process.env.VUE_APP_INTERCOM_BASE_URL;

  private baseBootPayload = {
    api_base: this.apiUrl,
    app_id: this.appId
  };

  public isBooted: boolean = false;

  public get supportLink(): string {
    return `https://app.intercom.com/a/apps/${this.appId}/inbox`;
  }

  public boot(options: TBootOptions): void {
    const { name, email, created_at } = options;
    const payload: TBootPayload = this.getBootPayload({ name, email, created_at });

    // @ts-ignore
    window.Intercom(EMethod.BOOT, payload);
    this.setBooted();
  }

  public bootQuizGuest(options: TBootOptions): void {
    const { name, email, created_at } = options;

    const payload = Object.assign(this.baseBootPayload, { name: name, anonymous_email: email, created_at: created_at, 
    "Signup Source": 'quiz_user' });

    console.log("payload");
    console.log(payload);

    // @ts-ignore
    window.Intercom(EMethod.BOOT, payload);

    console.log("Intercom");


    this.setBooted();
  }

  public bootGuest(): void {
    const payload = this.getBootPayload({ name: 'Guest', created_at: Math.round(new Date().getTime() / 1000) });
    // @ts-ignore
    window.Intercom(EMethod.BOOT, payload);
    this.setBooted();
  }

  private getBootPayload(options: TBootOptions): TBootPayload {
    const { name, email, created_at } = options;
    return Object.assign(this.baseBootPayload, { name, email, created_at });
  }

  private setBooted(value: boolean = true): void {
    this.isBooted = value;
  }

  public hide(): void {
    this.update({ hide_default_launcher: true });
    this.setBooted(false);
  }

  public show(): void {
    this.update({ hide_default_launcher: false });
    this.setBooted();
  }

  public update(options: TUpdateOptions): void {
    // @ts-ignore
    window.Intercom(EMethod.UPDATE, options);
  }

  public shutdown(): void {
    // @ts-ignore
    if (!window.Intercom) {
      return;
    }

    // @ts-ignore
    window.Intercom(EMethod.SHUTDOWN);
    this.isBooted = false;
  }

  public trackEvents(event:any , metaData:any): void {
    // @ts-ignore
    if (!window.Intercom) {
      return;
    }

    if(metaData) {
      // @ts-ignore
      window.Intercom(EMethod.TRACK_EVENT, `${event}_submitted`, metaData);
    } else {
      // @ts-ignore
      window.Intercom(EMethod.TRACK_EVENT, `${event}_submitted`);
    }

    this.isBooted = false;
  }


  switchToUser(options: TBootOptions): void {
    if (!this.isBooted) {
      // removeCookiesStartWith('intercom');
      this.shutdown();
      this.setBooted(false);
    }

    this.boot(options);
  }
}

export const intercomHandler = new IntercomHandler();
