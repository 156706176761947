/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * The range between start/end can include up to equivalent of 7 days in hours / 168 hours.
 */
export interface IndexAvailabilitiesRequestSchema {
  /** @format date */
  start: string;
  duration: number;
  doctor_id?: number;
}

/**
 * Initial purchase request
 */
export interface InitialPurchaseRequestSchema {
  /**
   * ID of the payment intent that was obtained on the checkout page load
   * @example pi_1DseIY2eZvKYlo2CWeGpyLde
   */
  payment_intent_id: string;
}

/**
 * Authenticate user
 */
export interface LoginUserRequestSchema {
  email: string;
  password: string;
  remember?: boolean;
}

export interface Address {
  id: number;
  first_name: string;
  last_name: string;
  line_1: string;
  line_2?: string;
  line_3?: string;
  city: string;
  state?: string;
  postcode: string;
  country_id: string;
  phone: string;
}

export interface Admin {
  id?: number;
}

export interface Consultation {
  id: number;
  is_initial: boolean;
  patient_id: number;
  doctor_id: number;
  status: string;
  /** @format date */
  starts_at: string;
  /** @format date */
  starts_at_formatted: string;
  /** @format date */
  ends_at_formatted: string;
  doctor_name: string;
  chime_meeting_id: string;
  can_be_joined: boolean;
  can_be_started: boolean;
  can_be_rescheduled: boolean;
  can_be_cancelled: boolean;
  is_amendment_paid: boolean;
  amendment_price_formatted: string;
  doctor_notes: string;
  expected_duration: string;
  real_duration: string;
  /** @format date */
  real_started_at: string;
  /** @format date */
  created_at: string;
}

export interface Doctor {
  id?: number;
  user_id?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  /** @format date */
  created_at?: string;
  patients_count?: number;
}

export interface DocumentVerification {
  id?: number;
  status?: string;
  rejection_reason?: string;
  files?: {
    id?: number;
    name?: string;
    extension?: string;
    size?: number;
    mime?: string;
    /** @format date */
    created_at?: string;
    download_url?: string;
  }[];
}

export interface Order {
  id: number;
}

export interface Patient {
  id?: number;
  user_id?: number;
  quiz_id?: number;
  /** @format date */
  birth_date?: string;
  gender?: string;
  id_verification_status?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  sampleCollectionMethod?: string;
  has_requested_venous?: boolean;
  /** If true it means the user for this patient exists in the system */
  is_registered?: boolean;
  /** If true it means that the patient has completed both registration steps */
  is_registration_complete?: boolean;
  viewed_questionnaire?: boolean;
  /** If true it means the patient has succesfully paid for their initial order */
  is_paying_customer?: boolean;
  /**
   * When the last registration step was completed.
   * @format date
   * @example 2022-08-14 23:00:39
   */
  registered_at?: string;
  /**
   * When the initial order has been paid
   * @format date
   * @example 2022-08-14 23:00:39
   */
  became_paying_at?: string;
  /**
   * Whether or not patient has completed all onboarding documents
   * @example 2022-08-14 23:00:39
   */
  has_completed_paperwork?: boolean;
  /** @example 10 */
  treatment_plans_count?: number;
  /** @example 10 */
  completed_consultations_count?: number;
  /** @format date */
  last_logged_in?: string;
  semble_profile_url?: string;
  semble_notes_url?: string;
  semble_id?: string;
  roseway_id?: string;
  has_completed_questionnaire?: boolean;
  /** @format date */
  next_consultation?: string;
  initial_consultation?: Consultation;
  subscription?: Subscription;
  discount_applicable: boolean;
}

export interface PatientCollection {
    data: Patient[];
    pagination: {
      current_page: number;
      last_page: number;
      total: number;
      per_page: number;
    };
  }

export interface PaymentIntent {
  id: string;
  clientSecret: string;
  /** @example £195.00 */
  amount: number;
}

export interface PricingPlan {
  id?: number;
  name?: string;
  /** @example £8.00 */
  shipping_cost?: string;
  /** @example £195.00 */
  price?: string;
}

export interface Subscription {
  id: number;
  status: string;
  /** @format date */
  activated_at: string;
  billing_model: string;
  consultations_credits: number;
  blood_tests_credits: number;
}

export interface TreatmentPlan {
  id: number;
  status?: string;
  /** @format date */
  created_at?: string;
  patient?: Patient;
  doctor?: Doctor;
  pricing_plan?: PricingPlan;
  /** @format date */
  signed_at_display?: string;
}

export interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  intercom_id?: string;
  patient?: Patient;
  doctor?: Doctor;
  admin?: Admin;
  /** @format date */
  created_at?: string;
  impersonation_mode?: boolean;
  has_verified_email?: boolean;
}

/**
 * Store new registered user request
 * Body data
 */
export interface RegisterUserRequestSchema {
  email: string;
  password: string;
  terms: boolean;
  /**
   * quiz_id
   * ID of the quiz. Present when user gets to the registration screen via quiz. In case of direct visit no Quiz ID is exepcted
   * @example 999
   */
  quiz_id?: number;
}

/**
 * Store treatment plan request payload
 */
export interface StoreTreatmentPlanRequestSchema {
  /** patient_id */
  patient_id: number;
  /** Optional status. Accepted values: draft|pending. If omitted, pending is used by default */
  status?: string;
  medications: {
    /** Medication ID */
    id?: number;
    usage?: string;
    month?: number;
    number_of_repeats?: number;
    line_item_amount?: number;
  }[];
}

export interface UpdateAddressRequestSchema {
  first_name?: any;
  last_name?: any;
  line_1: any;
  line_2?: any;
  line_3?: any;
  city: any;
  state?: any;
  postcode: any;
  phone: any;
}

/**
 * Update registered user details
 */
export interface UpdateRegisteredUserRequestSchema {
  address: {
    /** @example Jack */
    first_name: string;
    /** @example Doe */
    last_name: string;
    /** @example 500 Baker street */
    line_1: string;
    /** @example someting else */
    line_2?: string;
    /** @example and someting else */
    line_3?: string;
    /** @example London */
    city: string;
    /** @example London */
    state?: string;
    /** @example 127001 */
    postcode: string;
    /** @example GB */
    country_id: string;
    /** @example 123456789 */
    phone: string;
  };
  /** @example male | female */
  gender: string;
  /** @example test@example.com */
  email: string;
  /**
   * @format date
   * @example 1989-09-10
   */
  birth_date: string;
}

export namespace Login {
  /**
   * No description
   * @tags Registration
   * @name Login
   * @summary Log user in
   * @request POST:/login
   * @response `204` `void` User logged in
   * @response `422` `void` Unprocessable Entity
   */
  export namespace Login {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = LoginUserRequestSchema;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace Logout {
  /**
   * No description
   * @tags Registration
   * @name Logout
   * @summary Log user out
   * @request POST:/logout
   * @response `204` `void` User logged in
   */
  export namespace Logout {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace Register {
  /**
 * @description Registers new user
 * @tags Registration
 * @name Register
 * @summary Register user
 * @request POST:/register
 * @response `201` `{
  data?: User,

}` User created
 * @response `422` `void` Unprocessable Entity
*/
  export namespace Register {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = RegisterUserRequestSchema;
    export type RequestHeaders = {};
    export type ResponseBody = {
      data?: User;
    };
  } /**
 * @description Updates registered user details
 * @tags Registration
 * @name UpdateRegisterDetails
 * @summary Update registered user
 * @request PUT:/register/details
 * @response `200` `{
  data?: User,

}` User updated
*/
  export namespace UpdateRegisterDetails {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = UpdateRegisteredUserRequestSchema;
    export type RequestHeaders = {};
    export type ResponseBody = {
      data?: User;
    };
  }
}

export namespace Api {
  /**
 * No description
 * @tags User
 * @name Me
 * @summary Show authenticated user
 * @request GET:/api/me
 * @response `200` `{
  data?: User,

}` Show authenticated user
 * @response `401` `void` Unauthorized
*/
  export namespace Me {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = {
      data?: User;
    };
  } /**
 * No description
 * @tags Billing
 * @name CreatePaymentIntent
 * @summary Create payment intent
 * @request POST:/api/payment-intents
 * @response `201` `{
  data?: PaymentIntent,

}` Payment Intent created
*/
  export namespace CreatePaymentIntent {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = {
      data?: PaymentIntent;
    };
  } /**
 * No description
 * @tags Billing
 * @name InitialPurchase
 * @summary Make initial purchase
 * @request POST:/api/initial-purchases
 * @response `201` `{
  data?: Order,

}` Order created
*/
  export namespace InitialPurchase {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = InitialPurchaseRequestSchema;
    export type RequestHeaders = {};
    export type ResponseBody = {
      data?: Order;
    };
  } /**
 * No description
 * @tags IdVerification
 * @name ShowIdVerification
 * @request GET:/api/patient/documents/id
 * @response `200` `{
  data?: DocumentVerification,

}` Show id verification
 * @response `401` `void` Unauthorized
*/
  export namespace ShowIdVerification {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = {
      data?: DocumentVerification;
    };
  } /**
 * @description Lists available date-time slots to make a booking at
 * @tags Patient
 * @name GetAvailabilities
 * @summary Get availabilities
 * @request GET:/api/patient/availabilities
 * @response `200` `{
  data?: ({
  \** @format date-time *\
  start?: string,
  \** @format date-time *\
  end?: string,

})[],

}` Operation Successful
 * @response `422` `void` Unprocessable Entity
*/
  export namespace GetAvailabilities {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = IndexAvailabilitiesRequestSchema;
    export type RequestHeaders = {};
    export type ResponseBody = {
      data?: {
        /** @format date-time */
        start?: string;
        /** @format date-time */
        end?: string;
      }[];
    };
  } /**
 * No description
 * @tags Patient
 * @name ShowAddress
 * @summary Show patient address
 * @request GET:/api/patient/address
 * @response `200` `{
  data?: Address,

}` Successfull
 * @response `401` `void` Unauthorized
*/
  export namespace ShowAddress {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = {
      data?: Address;
    };
  } /**
 * No description
 * @tags Patient
 * @name UpdateAddress
 * @summary Update patient address
 * @request PUT:/api/patient/address
 * @response `200` `{
  data?: Address,

}` Address updated
 * @response `401` `void` Unauthorized
 * @response `422` `void` Unprocessable entity
*/
  export namespace UpdateAddress {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = UpdateAddressRequestSchema;
    export type RequestHeaders = {};
    export type ResponseBody = {
      data?: Address;
    };
  }
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded'
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer'
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input)
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {})
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body)
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Harmon API
 * @version 0.1
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  login = {
    /**
     * No description
     *
     * @tags Registration
     * @name Login
     * @summary Log user in
     * @request POST:/login
     * @response `204` `void` User logged in
     * @response `422` `void` Unprocessable Entity
     */
    login: (data: LoginUserRequestSchema, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/login`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params
      })
  };
  logout = {
    /**
     * No description
     *
     * @tags Registration
     * @name Logout
     * @summary Log user out
     * @request POST:/logout
     * @response `204` `void` User logged in
     */
    logout: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/logout`,
        method: 'POST',
        ...params
      })
  };
  register = {
    /**
 * @description Registers new user
 *
 * @tags Registration
 * @name Register
 * @summary Register user
 * @request POST:/register
 * @response `201` `{
  data?: User,

}` User created
 * @response `422` `void` Unprocessable Entity
 */
    register: (data: RegisterUserRequestSchema, params: RequestParams = {}) =>
      this.request<
        {
          data?: User;
        },
        void
      >({
        path: `/register`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
 * @description Updates registered user details
 *
 * @tags Registration
 * @name UpdateRegisterDetails
 * @summary Update registered user
 * @request PUT:/register/details
 * @response `200` `{
  data?: User,

}` User updated
 */
    updateRegisterDetails: (data: UpdateRegisteredUserRequestSchema, params: RequestParams = {}) =>
      this.request<
        {
          data?: User;
        },
        any
      >({
        path: `/register/details`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  };
  api = {
    /**
 * No description
 *
 * @tags User
 * @name Me
 * @summary Show authenticated user
 * @request GET:/api/me
 * @response `200` `{
  data?: User,

}` Show authenticated user
 * @response `401` `void` Unauthorized
 */
    me: (params: RequestParams = {}) =>
      this.request<
        {
          data?: User;
        },
        void
      >({
        path: `/api/me`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
 * No description
 *
 * @tags Billing
 * @name CreatePaymentIntent
 * @summary Create payment intent
 * @request POST:/api/payment-intents
 * @response `201` `{
  data?: PaymentIntent,

}` Payment Intent created
 */
    createPaymentIntent: (params: RequestParams = {}) =>
      this.request<
        {
          data?: PaymentIntent;
        },
        any
      >({
        path: `/api/payment-intents`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
 * No description
 *
 * @tags Billing
 * @name InitialPurchase
 * @summary Make initial purchase
 * @request POST:/api/initial-purchases
 * @response `201` `{
  data?: Order,

}` Order created
 */
    initialPurchase: (data: InitialPurchaseRequestSchema, params: RequestParams = {}) =>
      this.request<
        {
          data?: Order;
        },
        any
      >({
        path: `/api/initial-purchases`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
 * No description
 *
 * @tags IdVerification
 * @name ShowIdVerification
 * @request GET:/api/patient/documents/id
 * @response `200` `{
  data?: DocumentVerification,

}` Show id verification
 * @response `401` `void` Unauthorized
 */
    showIdVerification: (params: RequestParams = {}) =>
      this.request<
        {
          data?: DocumentVerification;
        },
        void
      >({
        path: `/api/patient/documents/id`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
 * @description Lists available date-time slots to make a booking at
 *
 * @tags Patient
 * @name GetAvailabilities
 * @summary Get availabilities
 * @request GET:/api/patient/availabilities
 * @response `200` `{
  data?: ({
  \** @format date-time *\
  start?: string,
  \** @format date-time *\
  end?: string,

})[],

}` Operation Successful
 * @response `422` `void` Unprocessable Entity
 */
    getAvailabilities: (data: IndexAvailabilitiesRequestSchema, params: RequestParams = {}) =>
      this.request<
        {
          data?: {
            /** @format date-time */
            start?: string;
            /** @format date-time */
            end?: string;
          }[];
        },
        void
      >({
        path: `/api/patient/availabilities`,
        method: 'GET',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
 * No description
 *
 * @tags Patient
 * @name ShowAddress
 * @summary Show patient address
 * @request GET:/api/patient/address
 * @response `200` `{
  data?: Address,

}` Successfull
 * @response `401` `void` Unauthorized
 */
    showAddress: (params: RequestParams = {}) =>
      this.request<
        {
          data?: Address;
        },
        void
      >({
        path: `/api/patient/address`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
 * No description
 *
 * @tags Patient
 * @name UpdateAddress
 * @summary Update patient address
 * @request PUT:/api/patient/address
 * @response `200` `{
  data?: Address,

}` Address updated
 * @response `401` `void` Unauthorized
 * @response `422` `void` Unprocessable entity
 */
    updateAddress: (data: UpdateAddressRequestSchema, params: RequestParams = {}) =>
      this.request<
        {
          data?: Address;
        },
        void
      >({
        path: `/api/patient/address`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  };
}
