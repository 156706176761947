import { EConsultationStatus } from '@/@types/domain';
import { convertDate, convertHoursAndMinutes } from '@/composables/useDate';
import { TConsultation } from './doctor.domain';

class DoctorMapper {
  public consultationsMapper = (consultations: TConsultation[]) => {
    return consultations.map((consultation: TConsultation) => {
      const { starts_at, id, status, real_duration, expected_duration } = consultation;
      const date = convertDate(starts_at);
      const time = convertHoursAndMinutes(starts_at);
      const title = `Consultation #${id}`;

      const duration = status === EConsultationStatus.COMPLETED ? real_duration : expected_duration;

      return { ...consultation, date, time, title, duration };
    });
  };
}

export const doctorMapper = new DoctorMapper();
