<template>
  <h-container>
    <template #tag>
      <div class="base-skeleton-item h-6 w-12"></div>
      <span
        class="text-app-p-xs pl-2 ml-2 relative before:content-['·'] before:absolute before:left-0 before:transform before:top-1/2 before:-translate-y-1/2"
      >
        <div class="base-skeleton-item h-6 w-24"></div>
      </span>
    </template>
    <div class="base-skeleton-item h-8 w-full"></div>
    <template #actions>
      <div class="base-skeleton-item h-6 w-6"></div>
    </template>
  </h-container>
</template>
