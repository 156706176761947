import type { RouteRecordRaw } from 'vue-router';
import admin from '@/middleware/admin';

const adminRoutes: RouteRecordRaw[] = [
  {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName : "admin" */ '@/views/Admin/AdminView.vue')
  },
  {
    path: '/admin/tasks',
    name: 'admin-tasks',
    component: () => import(/* webpackChunkName : "admin-tasks" */ '@/views/Admin/AdminTasksView.vue')
  },
  {
    path: '/admin/tasks/verify-patient-id/:patientId',
    name: 'admin-tasks-verify-patient-id',
    component: () =>
      import(/* webpackChunkName : "admin-tasks-verify-patient-id" */ '@/views/Admin/AdminVerifyPatientIdView.vue')
  },
  {
    path: '/admin/patients',
    name: 'admin-patients',
    component: () => import(/* webpackChunkName : "admin-patients" */ '@/views/Admin/AdminPatientsView.vue')
  },
  {
    path: '/admin/patients/:patientId',
    name: 'admin-patients-id',
    component: () => import(/* webpackChunkName : "admin-patients-id" */ '@/views/Admin/AdminPatientView.vue')
  },

  {
    path: '/admin/patients/:patientId/prescriptions',
    name: 'admin-patient-id-prescriptions',
    component: () =>
      import(
        /* webpackChunkName : "admin-patient-id-prescriptions" */ '@/views/Admin/AdminPatientSubscriptionsView.vue'
      )
  },
  {
    path: '/admin/patients/:patientId/prescriptions/:subscriptionId',
    name: 'admin-patient-id-prescriptions-subscriptionId',
    component: () =>
      import(/* webpackChunkName : "admin-patient-id-prescriptions-id" */ '@/views/Admin/PatientSubscriptionView.vue')
  },

  {
    path: '/admin/patients/:patientId/prescription',
    name: 'admin-patient-id-prescription',
    component: () =>
      import(
        /* webpackChunkName : "admin-patient-id-prescriptions" */ '@/views/Admin/AdminPatientPrescriptionsView.vue'
      )
  },

  {
    path: '/admin/patients/:patientId/prescription/:prescriptionId',
    name: 'admin-patient-id-prescription-id',
    component: () =>
      import(/* webpackChunkName : "admin-patient-id-prescription-id" */ '@/views/Admin/PatientPrescriptionView.vue')
  },


  {
    path: '/admin/patients/:patientId/blood-tests',
    name: 'admin-patient-id-blood-tests',
    component: () =>
      import(/* webpackChunkName : "admin-patient-id-prescriptions" */ '@/views/Admin/AdminPatientBloodTestsView.vue')
  },
  {
    path: '/admin/patients/:patientId/blood-tests/:bloodTestId',
    name: 'admin-patient-id-blood-test-id',
    component: () =>
      import(/* webpackChunkName : "admin-patient-id-prescriptions" */ '@/views/Admin/AdminPatientBloodTestView.vue')
  },

  {
    path: '/admin/patients/:patientId/transactions',
    name: 'admin-patient-id-transactions',
    component: () =>
      import(/* webpackChunkName : "admin-patient-id-transactions" */ '@/views/Admin/AdminPatientTransactionsView.vue')
  },
  {
    path: '/admin/doctors',
    name: 'admin-doctors',
    component: () => import(/* webpackChunkName : "admin-doctors" */ '@/views/Admin/AdminDoctorsView.vue')
  },
  {
    path: '/admin/doctors/:doctorId',
    name: 'admin-doctors-id',
    component: () => import(/* webpackChunkName : "admin-doctors-id" */ '@/views/Admin/AdminDoctorView.vue')
  },
  {
    path: '/admin/account',
    name: 'admin-account',
    component: () => import(/* webpackChunkName : "admin-account" */ '@/views/Admin/Settings/AccountView.vue')
  },
  {
    path: '/admin/account/email-address',
    name: 'admin-account-email-address',
    component: () =>
      import(/* webpackChunkName : "admin-account-email-address" */ '@/views/Admin/Settings/ChangeAdminEmailView.vue')
  },
  {
    path: '/admin/account/password',
    name: 'admin-account-password',
    component: () =>
      import(/* webpackChunkName : "admin-account-password" */ '@/views/Admin/Settings/ChangeAdminPasswordView.vue')
  },
  {
    path: '/admin/payment/config',
    name: 'admin-payment-config',
    component: () =>
      import(/* webpackChunkName : "admin-payment-config" */ '@/views/Admin/Settings/PaymentConfiguration.vue')
  },
  {
    path: '/admin/account/activity',
    name: 'admin-account-activity',
    component: () =>
      import(/* webpackChunkName : "admin-account-activity" */ '@/views/Admin/Settings/ActivitiesHistoryView.vue')
  },
  {
    path: '/admin/doctors/create',
    name: 'admin-doctor-create',
    component: () => import(/* webpackChunkName : "admin-doctor-create" */ '@/views/Admin/AdminCreateDoctorView.vue')
  },
  {
    path: '/admin/impersonation-logs/:userId',
    name: 'admin-impersonation-logs',
    component: () =>
      import(/* webpackChunkName : "admin-doctor-create" */ '@/views/Admin/AdminImpersonationLogsView.vue')
  },
  {
    path: '/discount/add-patient',
    name: 'discount-add-patient',
    component: () => import(/* webpackChunkName : "discount-add-patient" */ '@/views/Discount/AddPatient.vue')
  },
  {
    path: '/discount/patients',
    name: 'discount-patients',
    component: () => import(/* webpackChunkName : "discount-patients" */ '@/views/Discount/ListPatients.vue')
  },
  {
    path: '/promotions/add-promotions',
    name: 'add-promotions',
    component: () => import(/* webpackChunkName : "promotions-patients" */ '@/views/Discount/AddPromotions.vue')
  },
  {
    path: '/promotions/list-promotions',
    name: 'list-promotions',
    component: () => import(/* webpackChunkName : "promotions-patients" */ '@/views/Discount/ListPromotions.vue')
  },
  {
    path: '/promotions/patient-promotion-list/:promotionId/:promotionStatus',
    name: 'patient-promotion-list',
    component: () => import(/* webpackChunkName : "discount-patients" */ '@/views/Discount/PatientPromotionList.vue')
  }
];

adminRoutes.forEach((route) => {
  const middleware: [] = (route.meta?.middleware as []) || [];
  route.meta = { ...route.meta, middleware: [admin, ...middleware] };
});

export default adminRoutes;
