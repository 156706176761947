import { useNotificationStore } from '@/stores/Notification/NotificationStore';
import type {
  IActions,
  TRestorePassword,
  TCreatePayload,
  TGetters,
  TResetPassword,
  TSignInForm,
  TState,
  TUpdatePayload,
  TUser
} from './Domain';
import { ESignUpStep } from './Domain';
import { initUserState } from './Domain';
import UserService from './Service';
import { defineStore } from 'pinia';
import { EHttpStatus } from '@/core/http/Domain';
import { getMe } from '@/composables/useAuth';

const userService = new UserService();

const useUserStore = defineStore<'user', TState, TGetters, IActions>('user', {
  state: () => initUserState(),
  getters: {
    isDoctorAndAdmin(): boolean {
      return this.isDoctor && this.isAdmin;
    },
    currentUser(): any {
      if (!this.user) {
        return null;
      }

      if (this.isPatient) {
        return this.user.patient;
      }

      if (this.isDoctor) {
        return this.user.doctor;
      }

      if (this.isAdmin) {
        return this.user.admin;
      }

      return null;
    },
    hasVerifiedEmail(): boolean {
      return this.user?.has_verified_email || false;
    },

    patientName(): string {
      if (!this.isPatient) {
        return 'Patient';
      }

      const { first_name, last_name } = this.user;
      return `${first_name} ${last_name}`;
    },

    applicableForDiscount(): boolean {
      return this.user?.patient?.discount_applicable || false;
    },

    userProfileLink() {
      if (this.isAdmin) {
        return 'admin';
      } else if (this.isDoctor) {
        return 'doctor-dashboard';
      } else if (this.isPatient && !this.user?.patient.is_paying_customer) {
        return 'sign-up';
      } else if (this.isPatient && this.user?.patient.is_paying_customer) {
        return 'patient-dashboard';
      } else {
        return '';
      }
    },

    isFinishFirstStep(): boolean {
      if (!this.isPatient && !!this.user) {
        return true;
      }

      const { is_registered, is_registration_complete, is_paying_customer } = this.user?.patient || {};
      return ((is_registered || is_registration_complete) && !is_paying_customer) || false;
    },

    isTwoStepRegistrationFinish(): boolean {
      if (!this.isPatient && !!this.user) {
        return true;
      }

      const { is_registered, is_registration_complete } = this.user?.patient || {};
      return (is_registered && is_registration_complete) || false;
    },

    isAuth(): boolean {
      if (!this.user) {
        return false;
      }

      if (!this.isPatient && !!this.user) {
        return true;
      }

      const { is_registered, is_registration_complete, is_paying_customer } = this.user?.patient || {};
      const { has_verified_email } = this.user || {};
      return is_registered && is_registration_complete && is_paying_customer;
    },

    isPatient(): boolean {
      return !!this.user?.patient;
    },
    isDoctor(): boolean {
      return !!this.user?.doctor;
    },
    isAdmin(): boolean {
      return !!this.user?.admin;
    },
    patient(): TUser['patient'] {
      return this.user?.patient || null;
    },

    isImpersonateModeOn(): boolean {
      return !!this.user?.impersonation_mode;
    }
  },

  actions: {
    setIsLoading(): void {
      this.isLoading = true;
      this.isError = false;
    },

    removeIsLoading(): void {
      this.isLoading = false;
    },

    setIsError(): void {
      this.isError = true;
      this.isLoading = false;
    },

    removeIsError(): void {
      this.isError = false;
    },

    onSetUser(user: TUser): void {
      if (!user) {
        return;
      }

      this.user = user;
    },

    onSetSignUpStep(step: number): void {
      this.signUpStep = step === -1 ? ESignUpStep.SIGN_UP : step;
    },

    async onSignIn(payload: TSignInForm): Promise<void> {
      const status = await userService.signIn(payload);
      if (status !== EHttpStatus.NO_CONTENT) {
        return;
      }

      await getMe();
    },

    async onGetUser(): Promise<void> {
      const user = await userService.read();

      if (!user) {
        this.setIsError();
        return;
      }
      this.user = user;
    },

    async onCreateUser(payload: TCreatePayload): Promise<TUser> {
      const user = await userService.create(payload);
      if (!user) {
        this.setIsError();
        return;
      }

      this.user = user;
      return user;
    },

    async onUpdateUser(payload: TUpdatePayload): Promise<void> {
      const user = await userService.update(payload);
      if (!user) {
        this.setIsError();
      }

      Object.assign(this.user, user);
      await getMe();
    },

    async onDelete(): Promise<void> {
      const status = await userService.delete();
      // @ts-ignore
      if (status === EHttpStatus.NO_CONTENT) {
        this.$reset();
      }
    },

    async onSignOut(): Promise<void> {
      const status = await userService.signOut();
      if (status === EHttpStatus.NO_CONTENT) {
        this.$reset();
      }
    },

    async onForgotPassword(payload: TResetPassword): Promise<EHttpStatus> {
      const response = await userService.forgotPassword(payload);
      useNotificationStore().addNotification({ type: 'info', text: response?.data?.status || 'error' });
      this.status = response.status;
      return response.status;
    },

    async onResetPassword(payload: TRestorePassword): Promise<EHttpStatus> {
      const response = await userService.restorePassword(payload);
      let tempStatus = 200;
      // Customize error message based on response
      if (response?.response?.data?.errors) {
        tempStatus = response?.response?.status;
      } else {
        tempStatus = response?.status;
        useNotificationStore().addNotification({ type: 'info', text: 'Password reset successful.' });
      }

      this.status = tempStatus;
      return tempStatus;
    },


    async startImpersonateSession(id: number): Promise<void> {
      await userService.startImpersonateSession(id);
    },

    async finishImpersonateSession(): Promise<void> {
      await userService.finishImpersonateSession();
    },

    async onResendVerificationEmail(): Promise<void> {
      try {
        await userService.resendVerificationEmail();
        await getMe();
      } catch (e) {
        throw e;
      }
    }
  }
});
export { useUserStore };

// import { Store, Pinia } from 'pinia-class-component';
// import StoreCatcher from '../core/StoreCatcher';
// @Store
// // @ts-ignore
// class User extends Pinia implements IActions {
//   public state = initUserState();

//   public get user(): TUser {
//     return this.state.user;
//   }

//   public get isLoading(): boolean {
//     return this.state.isLoading;
//   }

//   public onSetSignUpStep(step: number): void {
//     this.state.signUpStep = step;
//   }

//   public onSetUser(user: TUser): void {
//     this.state.user = user;
//   }

//   @StoreCatcher()
//   public async onGetUser(): Promise<void> {
//     const user = await userService.read();
//     if (!user) {
//       return;
//     }

//     this.state.user = user;
//   }
//   @StoreCatcher()
//   public async onUpdateUser(payload: TUpdatePayload): Promise<void> {
//     const user = await userService.update(payload);
//     if (this.state.isError || !user) {
//       return;
//     }

//     Object.assign(this.state.user, user);
//   }

//   @StoreCatcher()
//   public async onCreateUser(payload: TCreatePayload): Promise<void> {
//     const user = await userService.create(payload);

//     if (this.state.isError || !user) {
//       return;
//     }

//     this.state.user = user;

//     this.onSetSignUpStep(ESignUpStep.SIGN_UP_DETAILS);
//   }
// }
// const useUserStore = new User();
// export { useUserStore };
