import type { Doctor, Patient, PatientCollection, TreatmentPlan, User } from '@/Api/Api';
import type { TFetchState } from '../core/Domain';
import { ETaskDue } from '@/@types/domain';

export enum EAdminTask {
  VERIFY_PATIENT_ID = 'verify-patient-id',
  DELETE_VERIFY_PATIENT_ID_DOC = 'delete-verify-patient-id-document',
  SIGN_PRESCRIPTION = 'sign-prescription',
  COMPLETE_VENOUS_DRAW_REQUEST = 'complete-venous-draw-request'
}

export type TDoctor = Doctor;
export type TPatient = Patient;
export type TAdmin = User;
export type TTreatmentPlan = TreatmentPlan;
export type TMetrics = any;
export type TPatientTransaction = any;
export type TAdminSingleTask = any;
export type TAdminTasks = { [key in ETaskDue]: TAdminSingleTask[] };

export type TCreateDoctorPayload = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  roseway_id: string;
  semble_id: string;
  semble_api_key: string;
};

export type TGetPatientsPayload = {
  search?: string;
  doctor_id?: string;
};

export type TState = TFetchState & {
  patients: TPatient[];
  patient: TPatient;
  patientsPlans: TTreatmentPlan[];
  admin: TAdmin;
  doctors: TDoctor[];
  doctor: TDoctor;
  metrics: TMetrics;
  patientTransactions: TPatientTransaction[];
  tasks: TAdminTasks;
  task: TAdminSingleTask;
  patientsApplicableForDiscount: {id: number, email: string}[]
  patientsApplicableForPromotion: {id: number}[]
  promotions: {id: number, title: string}[]
  pagination?:{
    current_page: number;
    last_page: number;
    total: number;
    per_page: number;
  };
};

export type TGetters = {
  fullName(): string;
  doctorFullName(): string;
  patientUserId(): number;

  isImpersonateModeOn(): boolean;
};

export interface IAdminService {
  readPatientTreatmentsPlan(id: number): Promise<any>;
  readPatient(id: number): Promise<TPatient>;
  readPatients(search: string, page?:number): Promise<PatientCollection>
  confirmPatientQuestionnaire(patientId: number): Promise<any>;
  fetchVerifyInfo(patientId: number): Promise<any>;
  verifyPatient(patientId: number): Promise<any>;
  rejectVerification(patientId: number, comment: string): Promise<any>;
  readPatientTransactions(patientId: number): Promise<any>;

  readTasks(): Promise<TAdminTasks>;
  completeTask(taskId: number): Promise<void>;
  readMetrics(): Promise<any>;

  readDoctors(search?: string): Promise<TDoctor[]>;
  readDoctor(id: number): Promise<TDoctor>;
  createDoctor(payload: TCreateDoctorPayload): Promise<any>;
  addPatientApplicableForDiscount(payload: { email: string }): Promise<any>;
  readPatientsApplicableForDiscounts(): Promise<{ id: number, email: string}[]>;
  deletePatientApplicableForDiscounts(id: number): Promise<void>;

  addPromotion(payload: { title: string }): Promise<any>;
  readPromotion(): Promise<{ id: number, title: string}[]>;
  deletePromotion(id: number): Promise<void>;
  readPatientsApplicableForPromotions(id: number): Promise<void>;
  deletePatientApplicableForPromotion(id: number): Promise<void>;
}

export type IActions = {
  onGetMetrics(): Promise<void>;
  onSetAdmin(admin: TAdmin): void;
  onGetTasks(): Promise<void>;
  onCompleteTask(taskId: number): Promise<void>;
  setTask(task: TAdminSingleTask): void;

  onGetPatients(search?: string): Promise<void>;
  onGetPatient(id: number): Promise<void>;
  onGetPatientTreatmentsPlan(id: number): Promise<void>;
  onSetPatient(patient: TPatient): void;
  onGetPatientTransactions(patientId: number): Promise<void>;
  onResetPatient(): void;
  onConfirmPatientQuestionnaire(patientId: number): Promise<void>;

  onGetDoctors(search?: string): Promise<void>;
  onAddDoctor(payload: TCreateDoctorPayload): Promise<void>;
  onGetDoctor(id: number): Promise<void>;
  onSetDoctor(doctor: TDoctor): void;
  onAddPatientApplicableForDiscount(payload: { email: string }): Promise<any>;
  onGetPatientsApplicableForDiscount(): Promise<void>;
  onDeletePatientApplicableForDiscount(patient: {id: number, email: string}): Promise<void>;

  onAddPromotion(payload: { title: string }): Promise<any>;
  onGetPromotion(): Promise<void>;
  onDeletePromotion(promotion: {id: number, title: string}): Promise<void>;
  onDisabledPromotion(promotion: {id: number, title: string}, status: number): Promise<void>;
  onGetPatientsApplicableForPromotion(id: number): Promise<void>;
  onDeletePatientApplicableForPromotion(id: number): Promise<void>;
};

export const initAdminState = (): TState => {
  return {
    isLoading: false,
    isError: false,
    patientTransactions: [],
    patient: null,
    patientsPlans: [],
    admin: null,
    patients: [],
    doctors: [],
    doctor: null,
    metrics: null,
    tasks: null,
    task: null,
    patientsApplicableForDiscount: [],
    patientsApplicableForPromotion: [],
    promotions: []
  };
};

export const taskNameDictionary: { [task: string]: string } = {
  [EAdminTask.VERIFY_PATIENT_ID]: 'Verify Patient ID',
  [EAdminTask.DELETE_VERIFY_PATIENT_ID_DOC]: 'Delete Verified Selfie and Documents on Semble',
  [EAdminTask.SIGN_PRESCRIPTION]: 'Sign prescription on Roseway',
  [EAdminTask.COMPLETE_VENOUS_DRAW_REQUEST]: 'Complete the venous draw request'
};
