import { EVerificationIdStatus } from '@/@types/domain';
import { adminService } from './admin.service';
import { defineStore } from 'pinia';
import {
  IActions,
  initAdminState,
  TAdmin,
  TAdminSingleTask,
  TCreateDoctorPayload,
  TDoctor,
  TGetters,
  TPatient,
  TState
} from './admin.domain';

const useAdminStore = defineStore<'admin', TState, TGetters, IActions>('admin', {
  state: () => initAdminState(),

  getters: {
    patientUserId(): number | null {
      if (!this.patient) {
        return null;
      }

      return this.patient.user_id;
    },
    patientEntity(): TPatient | null {
      return this.patient;
    },
    fullName(): string {
      if (!this.admin) {
        return 'Admin';
      }

      const { last_name, first_name } = this.admin;
      return `${first_name} ${last_name}`;
    },

    doctorFullName(): string {
      if (!this.doctor) {
        return '';
      }

      const { first_name, last_name } = this.doctor;
      return `${first_name} ${last_name}`;
    },

    isImpersonateModeOn(): boolean {
      return !!this.admin?.impersonation_mode;
    }
  },

  actions: {
    async onCompleteTask(taskId: number): Promise<void> {
      await adminService.completeTask(taskId);
      await this.onGetTasks();
    },

    async onGetTasks(): Promise<void> {
      const tasks = await adminService.readTasks();
      for (const task in tasks) {
        // @ts-ignore
        tasks[task].sort((a: TAdminSingleTask, b: TAdminSingleTask) => {
          return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
        });
      }
      this.tasks = tasks;
    },

    setTask(task: TAdminSingleTask): void {
      this.task = task;
    },

    async onGetPatientTransactions(patientId: number): Promise<void> {
      this.patientTransactions = await adminService.readPatientTransactions(patientId);
    },

    async onConfirmPatientQuestionnaire(patientId: number): Promise<void> {
      const patient = await adminService.confirmPatientQuestionnaire(patientId);
      this.patient = patient;
      const listPatient = this.patients.find((i) => i.id === patient.id);
      if (listPatient) {
        listPatient.has_completed_questionnaire = true;
      }
    },

    async onGetMetrics(): Promise<void> {
      this.metrics = await adminService.readMetrics();
    },

    onSetDoctor(doctor: TDoctor): void {
      this.doctor = doctor;
    },

    async onGetDoctor(id: number): Promise<void> {
      this.doctor = await adminService.readDoctor(id);
    },

    async onGetPatientTreatmentsPlan(id: number): Promise<void> {
      this.patientsPlans = await adminService.readPatientTreatmentsPlan(id);
    },

    async onAddDoctor(payload: TCreateDoctorPayload): Promise<any> {
      return await adminService.createDoctor(payload);
    },

    onSetPatient(patient: TPatient): void {
      this.patient = patient;
    },

    onSetAdmin(admin: TAdmin): void {
      this.admin = admin;
    },

    async onGetPatient(id: number): Promise<void> {
      const { data } = await adminService.readPatient(id);
      this.patient = data.data;
    },

    onResetPatient(): void {
      this.patient = null;
    },

    async onGetPatients(search: string = '',page: number = 1): Promise<void> {
      const patients = await adminService.readPatients(search,page);
      this.pagination= patients.pagination;
      this.patients = patients.data;
    },


    async onGetDoctors(search: string = ''): Promise<void> {
      this.doctors = await adminService.readDoctors(search);
    },

    async onAddPatientApplicableForDiscount(payload: { email: string }): Promise<any> {
      const { data } = await adminService.addPatientApplicableForDiscount(payload);
      return data;
    },

    async onGetPatientsApplicableForDiscount(): Promise<void> {
      this.patientsApplicableForDiscount = await adminService.readPatientsApplicableForDiscounts();
    },

    async onDeletePatientApplicableForDiscount(patient: {id: number, email: string}): Promise<void> {
      await adminService.deletePatientApplicableForDiscounts(patient.id);
    },

    async onAddPromotion(payload: { title: string }): Promise<any> {
      const { data } = await adminService.addPromotion(payload);
      return data;
    },

    async onGetPromotion(): Promise<void> {
      this.promotions = await adminService.readPromotion();
    },

    async onDeletePromotion(promotion: {id: number, title: string}): Promise<void> {
      await adminService.deletePromotion(promotion.id);
    },
    async onDisabledPromotion(promotion: {id: number, title: string}, status: number): Promise<void> {
      await adminService.disabledPromotion(promotion.id, status);
    },
    async onDeletePatientApplicableForPromotion(id: number): Promise<void> {
      await adminService.deletePatientApplicableForPromotion(id);
    },
    async onGetPatientsApplicableForPromotion(id: number): Promise<void> {
      this.patientsApplicableForPromotion = await adminService.readPatientsApplicableForPromotions(id);
    },

  }
});

export { useAdminStore };
