import { TUserStore } from '@/stores/User/Domain';
import { TRouterGuard } from '@/@types/router';
import { useUser } from '@/composables/useUser';

export default function ({ to, next }: TRouterGuard, store: TUserStore) {
  const { userProfileLink } = useUser();
  const { path } = to;

  const redirectQueryParam = { query: { 'return-redirect-url': path } };
  // until we have /home and /about pages
  if (['/', '/about'].includes(path)) {
    next({ name: userProfileLink.value, ...redirectQueryParam });
  }

  if (store.isAuth) {
    return;
  }

  let baseName = 'sign-in';
  if (!store.isPatient) {
    next({ name: baseName, ...redirectQueryParam });
    return;
  }

  const { user } = store;
  if (!user?.patient?.is_registration_complete) {
    baseName = 'sign-up';
  }
  next({ name: baseName });
}
