export enum EButtonType {
  SUBMIT = 'submit',
  BUTTON = 'button',
  RESET = 'reset',
  LINK = 'link'
}

export type TComponentClass = { [x: string]: string | number };

export enum EButtonAppearance {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TRANSPARENT = 'transparent',
  INVERTED = 'inverted',
  CANCEL = 'cancel',
  ALERT = 'alert'
}

export enum EInputType {
  TEXT = 'text',
  NUMBER = 'number',
  PASSWORD = 'password',
  EMAIL = 'email',
  SEARCH = 'search'
}

export type TInputType = 'text' | 'number' | 'password' | 'email';

export enum EComponentName {
  ROUTER_LINK = 'router-link',
  BUTTON = 'button',
  ANCHOR = 'a'
}

export enum ETagAppearance {
  DEFAULT = 'default',
  LIME = 'lime',
  MINT = 'mint',
  CLOUD = 'cloud',
  IRON = 'iron',
  TASMAN = 'tasman',
  ACCEPTED = 'accepted',
  PENDING = 'pending',
  SCHEDULED = 'scheduled',
  CONFIRMED = 'confirmed',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  APPROVED = 'approved',
  APPROVAL = 'default',
  DRAFT = 'draft',
  REJECTED = 'rejected'
}

export type TStep = {
  to: string;
  id?: number;
  name: string;
  disabled?: boolean;
};

export enum EStepState {
  INCOMPLETE = 1,
  CURRENT,
  COMPLETED
}

export enum ENotificationType {
  SUCCESS = 'success',
  INFO = 'info',
  ERROR = 'error',
  GENERAL = 'light'
}

export enum EDashboardAppearance {
  ADMIN = 'admin',
  PATIENT = 'patient'
}

export enum ETooltipDirection {
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left'
}

export enum ETooltipAlign {
  CENTER = 'center',
  START = 'start',
  END = 'end'
}
