import { TUserStore } from '@/stores/User/Domain';
import { TRouterGuard } from '@/@types/router';
import { useUser } from '@/composables/useUser';
import { getQueryParams } from '@/helpers/url';

export default function ({ to, from, next }: TRouterGuard, store: TUserStore) {
  if (!store.isAuth) {
    return;
  }

  if (store.isDoctor) {
    const queries = getQueryParams();
    const returnUrl = queries['return-redirect-url'];

    if (returnUrl && returnUrl !== to.path) {
      next({ path: returnUrl });
    } else {
      next();
    }

    return;
  }

  const { userProfileLink } = useUser();
  next({ name: userProfileLink.value });
}
